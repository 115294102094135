.hazardsContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.titleHead {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #303539;
  padding-left: 10px;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.headingWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 34px;
}

.settingsCard {
  min-width: 487px;
  max-width: 487px;
  border: 5px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  .settingsCardHeader {
    border-radius: 5px 5px 0px 0px;
    background: #def3f0;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    .cardTitle {
      color: #28353c;
      text-align: center;
      /* Body regular OpenSans */
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%; /* 19.32px */
      letter-spacing: 0.105px;
      margin-left: 20px;
    }
  }
  .settingsCardBody {
    display: flex;
    margin-top: 50px;
    margin-left: 50px;
    border-radius: 0px 0px 5px 5px;
    justify-content: space-between;
    align-items: center;

    .cardContent {
      color: #28353c;
      /* Body regular OpenSans */
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%; /* 19.32px */
      letter-spacing: 0.105px;
      margin-right: 20px;
      margin-left: 50px;
    }
  }
}
