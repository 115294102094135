.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 80vh;
  width: 70vw;
}

.backIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 10px 10px 10px 0px;
}
.none {
  display: none !important;
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 138%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #7185be;
}
.qrWrapper {
  display: none;
}
.displayQRContainer {
  width: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  .QRItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 138%;
      /* or 48px */

      text-align: center;
      letter-spacing: 0.0075em;

      /* KS_28353C */

      color: #28353c;
      padding-bottom: 30px;
    }
  }
}
@media print {
  html,
  body {
    height: 300vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

@media print {
  .container {
    width: 100%;
  }
  .backIcon,
  .title {
    display: none;
  }
  .displayQRContainer {
    display: none;
  }
  .wrapper {
  }
  .qrRow {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  .qrWrapper {
    position: relative;
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .qrCodeCheckIn {
    background-color: #ffffff;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .qrCodeLogoBar {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 417.6px;
    height: auto;
    margin: 104px auto;
    // margin: 100px auto 58px auto;
  }
  .checkInIcon {
    width: 228px;
    margin-bottom: 18px;
  }

  .qrCodeLogoBar img {
    max-width: 100%;
  }

  .qrTitle {
    color: #fff;
    font-weight: bold;
    font-size: 48px;
    padding-top: 30px;
  }
  .qrRow {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 50px;
    height: auto;
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 28.8px;
      line-height: 138%;
      /* or 22px */

      letter-spacing: 0.0075em;

      /* KS_28353C */

      color: #28353c;
      padding-left: 35px;
    }
  }
  .qrCol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    z-index: 999;
    position: relative;

    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 63px;
      line-height: 138%;
      /* or 48px */

      text-align: center;
      letter-spacing: 0.0075em;

      /* KS_28353C */

      color: #28353c;
    }
  }
  .qrCodeWrapper {
    background: #ffffff;
    background-color: #ffffff;
    border-radius: 20px;
    position: relative;
    z-index: 4;
    opacity: 1;
    padding: 40px;
    --webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 40px;
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.24) 0px 3px 8px);
  }
  .leftTop {
    width: 35px;
    height: 35px;
    border-top: 3px solid #000000;
    border-left: 3px solid #000000;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .rightTop {
    width: 35px;
    height: 35px;
    border-top: 3px solid #000000;
    border-right: 3px solid #000000;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .leftBottom {
    width: 35px;
    height: 35px;
    border-bottom: 3px solid #000000;
    border-left: 3px solid #000000;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  .rightBottom {
    width: 35px;
    height: 35px;
    border-bottom: 3px solid #000000;
    border-right: 3px solid #000000;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .container {
    display: block !important;
    flex-direction: unset !important;
  }

  .qrCodeCheckOut,
  .qrCodeSiteInduction {
    margin-top: 80vh;
    background-color: #44c032;
    min-height: 80vh;
    position: relative;
  }

  .qrCodeFooter h2 {
    color: #fff;
    margin-left: 20px;
    text-align: left;
    font-size: 24px;
    width: auto;
  }

  .keepsafeBar {
    position: absolute;
    bottom: 75px;
    left: 10%;
    width: 80%;
    text-align: center;
    justify-content: center;
    align-items: center;
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 72px;
      line-height: 138%;
      /* or 55px */

      text-align: center;
      letter-spacing: 0.0075em;
      z-index: 99;
      position: relative;

      /* KS_28353C */

      color: #28353c;
      cursor: pointer;
      .green {
        color: #44c032;
      }
    }
  }
  .siteInductionHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 4;
  }
  .siteInductionTitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 63px;
    line-height: 138%;
    /* or 48px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_28353C */

    color: #28353c;
    position: relative;
    z-index: 4;
    margin-bottom: 36px;
  }
  .siteInductionText {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 138%;
    /* or 34px */

    text-align: center;
    letter-spacing: 0.0075em;
    position: relative;
    z-index: 4;
    margin-bottom: 36px;
    color: #28353c;
  }
  .subTextWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 36px auto;
    width: 90%;
  }
  .siteInducionSubtext {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 138%;
    /* or 28px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_28353C */
    align-items: center;
    justify-content: center;

    color: #28353c;
    position: relative;
    z-index: 4;
  }
}
