@import "../../styles/variables";

.backArrowWrapper {
  display: flex;
  justify-content: center;
  width: 44px;
  height: 44px;
  /* KS_FFF */
  border-radius: 44px;
  margin-top: 10px;
  background: #ffffff;
  /* E26C82 */

  border: 1px solid map-get($colors, c2);
  box-sizing: border-box;
  align-items: center;
  z-index: 999;
  margin-bottom: 20px;
  cursor: pointer;
}
.backArrow {
  width: 17px;
  height: 11px;
}
.container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 100vh;
  // min-height: calc(100vh - 160px);
  height: inherit;
  z-index: 999;
  background-color: map-get($colors, c5);
  // padding-bottom: 30px;
  display: none;

  &.open {
    display: block;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* 444444 */

    color: map-get($colors, c11);
    padding-bottom: 5px;
  }
  h1 {
    padding-bottom: 5px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* 444444 */

    color: map-get($colors, c11);
  }
  ul {
    li {
      padding-bottom: 3px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 138%;
      /* or 19px */

      letter-spacing: 0.0075em;

      /* 444444 */

      color: map-get($colors, c11);
    }
  }
}

.policyWrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 80px;
}
