.questionItem {
  width: 98%;
  padding: 10px;
  min-height: 58px;
  line-height: 28px;
  color: #fff;
  background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin: 4vh auto;
  cursor: pointer;
}

.questionItemChecked {
  width: 98%;
  padding: 10px;
  min-height: 58px;
  line-height: 28px;
  color: #fff;
  background-color: #ffc200;
  border: 2px solid #ffc200;
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin: 4vh auto;
  cursor: pointer;
}

.questionItemCorrect {
  background-color: #44c032 !important;
  border: 2px solid #44c032 !important;
}

.questionItemWrong {
  background-color: #ff4b00 !important;
  border: 2px solid #ff4b00 !important;
}

.questionItemImage {
  width: 46% !important;
  float: left;
  margin-left: 2%;
  min-height: 20vh;
  margin-bottom: 15px;
  margin-top: 15px;
}

#sendButton {
  position: fixed;
  bottom: 11px;
  z-index: 10;
  width: 98%;
  margin: 0 auto;
  left: 1%;
}

.correctAnswer {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  color: #28a745;
}

.wrongAnswer {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  color: #ff4b00;
}

.SummaryTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #0f1619;
  margin-top: 20px;
}

.SummaryImageWrapper {
  margin-top: 10vh;
  margin-bottom: 10vh;
  text-align: center;
  transition: 500ms;
  transition-delay: 300ms;
  transform: scale(1);
  opacity: 1;
  min-height: 105px;
}

.SummaryImageWrapperHidden {
  opacity: 0;
  transition: 500ms;
  transition-delay: 300ms;
  transform: scale(0.1);
  min-height: 105px;
}

.SummaryText {
  margin-top: 4vh;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138%;
  color: #0f1619;
}

.SummarySubText {
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  color: #28353c;
}

.assessmentButton {
  position: fixed;
  bottom: 0;
  z-index: 100;
  left: 0;
  width: 100%;
}

.assessmentWrapper {
  padding: 10px;
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0075em;
    color: #28353c;
    max-width: 100%;
  }
}

.SummaryWrapperHidden {
  opacity: 0;
  transition: 300ms;
  position: fixed;
  width: 100%;
  pointer-events: none;
  max-width: 750px;
}

.SummaryWrapper {
  opacity: 1;
  transition: 300ms;
  position: fixed;
  left: 0;
  width: 100%;
  max-width: 750px;
}

.assessmentWrapperHidden {
  opacity: 0;
  transition: 100ms;
}

@media (min-width: 1024px) {
  .assessmentButton {
    bottom: 9px;
  }

  .assessmentButton button {
    height: 62px;
  }
  .assessmentButton button:hover,
  .assessmentButton button:focus {
    background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
  }
}
