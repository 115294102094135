.selectbox {
  position: relative;
  padding-right: 20px;
  .selectbox_head {
    background: transparent;
    display: flex;
    justify-content: center;
    width: 100%;

    max-width: 200px;
    align-items: center;
    outline: none;
    border: none;
    &:focus {
      outline: none;
    }
    &:hover {
      outline: none;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      font-family: Open Sans;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
      white-space: nowrap;
      margin-right: 6px;
    }
    svg {
      margin-left: auto;
    }
  }

  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.0075em;

    /* FRL Deep Ocean Grey */

    color: #303539;
  }

  .dropdown {
    max-height: 190px;
    min-width: 200px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    position: relative;
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    position: absolute;
    top: calc(100% + 8px);
    left: 0px;
    right: 0px;
    display: none;
    z-index: 99999999;
    background: white;
    

    &.open {
      display: block;
    }

    h6 {
      font-weight: bold;
      font-size: 14px;
      line-height: 120%;
      padding: 16px 8px 8px 8px;
      color: #1f252c;
    }

    ul {
      padding: 0px 0px 0px 0px;
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;

      & li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 15px 8px 15px;
        &:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
        &:hover {
          background: #fafafa;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding-left: 8px;
          min-height: 35px;
          cursor: pointer;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 138%;
          /* identical to box height, or 17px */

          letter-spacing: 0.0075em;

          /* KS_28353C */

          color: #28353c;

          svg {
            margin-right: 8px;
          }
        }

        &:last-child {
          margin-bottom: 0px;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
}

.rotate {
  transform: rotate(180deg);
}

.counterLabel {
  position: absolute;
  width: 18px;
  height: 15px;
  background-color: #03ce91;
  border-radius: 13px;
  color: white;
  top: -15px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: normal;
    font-size: 10px !important;
    font-family: Open Sans;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
    color: #ffffff;
    text-align: center;
    margin-right: 0px !important;
  }
}

.filterInput {
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  background: #f8f8f8;
  display: flex;
  flex-direction: row;
  border-radius: 6px 6px 0px 0px;
  height: 33px;
  justify-content: center;
  align-items: center;
  .searchIcon {
    width: 12px;
    height: 12px;
  }
  .input {
    height: 33px;
    display: block;
    width: 80%;

    background: #f8f8f8;
    /* E0E0E0 */
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 0px 16px;
    font-family: Open Sans;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    font-size: 12px;
    color: #40507e;
    font-weight: bold;

    &:focus {
      outline: none;
    }
    &::placeholder {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.0075em;
      font-family: Open Sans;
      color: #a5b4c9;
      margin-top: 10px;
    }
  }
}

.optionBoxWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.optionBox {
  width: 40%;
  height: 18px;
  background: #87ab9f;
  border-radius: 4px;
  margin: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 138%;
    /* identical to box height, or 14px */

    letter-spacing: 0.0075em;

    /* KS_FFF */

    color: #ffffff;
  }
  img {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}

.gray {
  background-color: #999999;
  width: 50%;
}

.loadingWrapper {
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
