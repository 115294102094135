.modalContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 100%;
}
.modal {
  z-index: 2001;
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
}
.modalBackground {
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  z-index: 2000;
  top: 0;
  left: 0;
  background: #a5b4c9;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerContainer {
  width: 100%;
  height: 60px;
  background: rgba(165, 180, 201, 0.2);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: flex;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 138%;
    display: flex;
    text-align: center;
    letter-spacing: 0.0075em;
    color: #40507e;
  }
}
.bodyContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
}
.wrapper {
  margin-top: 9px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #40507e;
  margin-right: 29px;
}
.description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #03ce91;
  margin-bottom: 9px;
}
.footerContainer {
  display: flex;
  width: 100%;
  height: 48px;
  align-self: flex-end;
}
.cancelButton {
  display: flex;
  flex: 1;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  justify-content: center;
  align-items: center;
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* 7185BE */

    color: #7185be;
  }
}
.sendButton {
  display: flex;
  cursor: pointer;
  flex: 1;
  background: #7185be;
  border-radius: 0px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;
    color: #ffffff;
  }
}

.disabledButton {
  opacity: 0.7;
}

.deleteHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 64px;
  background: rgba(226, 108, 130, 0.2);
  border-radius: 10px 10px 0px 0px;
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 138%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;
    color: #dc3545;
  }
}
.deleteBody {
  span {
    margin-top: 28px;
    margin-left: 72px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;
    color: #40507e;
  }
}
.deleteFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.deleteCancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: #ffffff;
  border: 1px solid #7185be;
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  width: 121px;
  margin: 20px 10px;
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* 7185BE */

    color: #7185be;
  }
}
.deleteButton {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 40px;
  background: #e26c82;
  border-radius: 6px;
  margin: 20px 20px 20px 10px;

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    color: #ffffff;
  }
}

.tabContainer {
  border-radius: 22px;
  width: 90%;
  // min-height: 120px;
  display: flex;
  flex-direction: row;
  border: 1px solid #7185be;
  height: 44px;
  margin: 0 auto;
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;

    /* 7185BE */

    color: #7185be;
  }
}
.tab1 {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.activetab1 {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 22px 0px 0px 22px;
  background: #40507e;
  span {
    color: white;
    font-weight: 600;
  }
}
.desc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
  margin: 23px;
  height: 50px;
}
.selectText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 40507E */
  color: #40507e;
  height: 100px;
}

.inputCont {
  height: 80px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 23px;
}

.chooseHeaderContainer {
  background-color: #a5b4c9;
  span {
    color: #fff;
  }
}

.greenHeaderContainer {
  background-color: #6fcf97;
  span {
    color: #fff;
  }

  position: relative;
}

.redHeaderContainer {
  background-color: #e26c82;
  span {
    color: #fff;
  }
  position: relative;
}

.cardModalContainer .tabContainer,
.cardModalContainer .activetab1 {
  border: none;
  background: none;
}

.cardModalContainer .tabContainer {
  width: 50%;
  margin: 0 auto;
}

.cardModalContainer .inputCont textarea {
  height: 100px;
  width: 80%;
}

.cardTypeContainer {
  display: block;
  width: 100%;
  padding-left: 20px;
}

.cardType {
  background: #ffffff;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100px;
  float: left;
}

.issuer {
  float: left;
  margin-left: 50px;
  border-left: 1px solid #e9ebec;
  height: 100px;
  padding-left: 20px;
}

.issuerPretext {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7185be;
}

.threedots {
  position: absolute;
  right: 15px;
}

.mn120 {
  min-height: 120px;
}
