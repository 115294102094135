.regionsContainer {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
