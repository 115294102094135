.hazardsContainer {
  width: 100%;
  margin-bottom: 100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.headingContainer {
  width: 100%;
  position: sticky;
  top: 56px;
  right: 0px;
  z-index: 9999999999;
  background: rgb(246, 247, 250);
  padding-top: 20px;
}

.titleHead {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #303539;
  padding-left: 10px;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.checkboxWrapper {
  margin: 20px auto 10px auto;
}

.flex {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 80vh;
  width: 70vw;
}
.backIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headingWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*padding-bottom: 34px;*/
}

.h154 {
  height: 154px;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
}
.listContainer {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  height: inherit;
  flex-direction: column;
  position: relative;
  padding: 5px;
  padding-left: 27px;
  padding-right: 27px;
}

.listContainerActive {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  position: relative;
  padding: 5px;
  padding-left: 27px;
  padding-right: 27px;
  height: 65vh !important;
  overflow-y: scroll;
}
.listContainerActive::-webkit-scrollbar {
  display: none;
}


.editContainer {
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 65vh;
  overflow-y: scroll;
  /*animation: fadeIn 1s;*/
}

.editContainer::-webkit-scrollbar {
  display: none;
}

.drop {
  background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
  opacity: 0.3;
  width: 100%;
  height: 300vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  animation: fadeInDrop 1s;
}
.mb0 {
  margin-bottom: 0 !important;
}
.z100 {
  z-index: 99;
  position: relative;
}

//edit area styles

@keyframes fadeInDrop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1000px) {
  .headingContainer {
    padding-top: 0;
  }
  .titleHead
  {
    margin: 0;
  }

}

.selectedButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.slideIn {
  animation: slide-in 0.75s forwards;
  -webkit-animation: slide-in 0.75s forwards;
}

.slideOut {
  animation: slide-out 0.75s forwards;
  -webkit-animation: slide-out 0.75s forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(120%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(120%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(120%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(120%);
  }
}

.loadingContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
