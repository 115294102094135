.companyContainer {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.titleHead {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 138%;
  /* or 33px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* FRL Deep Ocean Grey */

  color: #303539;
  border-bottom: 1px solid rgba(165, 180, 201, 0.5);
  padding-bottom: 34px;
}
