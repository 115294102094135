@import "../../styles/variables";

.FormWrapper {
  margin: 20px;
  padding-bottom: 80px;
  min-height: calc(100vh - 273px);
  max-width: 100vw;
}
.wrapper {
  position: relative;
}
.Container {
  width: 100%;
  margin-bottom: 51px;
  position: relative;
}

.BlockLabel {
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 55px;
  color: map-get($colors, c1);
  margin-left: 20px;
}

@media (min-width: 320px) and (max-width: 375px) {
  .Container {
    width: 100%;
    margin-bottom: 35px;
    position: relative;
  }

  .BlockLabel {
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: map-get($colors, c1);
  }
}
@media (min-width: 375px) {
  .Container {
    width: 100%;
    margin-bottom: 35px;
    position: relative;
  }

  .BlockLabel {
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-size: 14px;
    line-height: 30px;
    color: map-get($colors, c1);
  }
}
.backArrowWrapper {
  display: flex;
  justify-content: center;
  width: 44px;
  height: 44px;
  /* KS_FFF */
  border-radius: 44px;
  margin-top: 20px;

  background: #ffffff;
  /* E26C82 */

  border: 1px solid map-get($colors, c2);
  box-sizing: border-box;
  align-items: center;
  margin-left: 20px;
  z-index: 999;
}
.backArrow {
  width: 17px;
  height: 11px;
}

.custom_checkbox {
  display: inline-flex;
  align-items: center;
  height: 20px;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;

  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  color: map-get($colors, c2);

  span {
    margin-top: 3px;
    font-size: 14px;
    line-height: 20px;
    color: #28353c;
    margin-bottom: 6px;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &.disabled {
    // color: map-get($colors, c1);
    cursor: not-allowed;
  }
  .checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 0px;
    background-color: white;
    border: 2px solid map-get($colors, c2);
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid map-get($colors, c);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}
input:focus + .checkmark {
  // border: 1px dashed map-get($colors, c4);
}

.readMore {
  color: map-get($colors, c2);
  text-decoration: underline;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  padding-left: 30px;
  cursor: pointer;
}

.termsWrapper {
  display: flex;
  flex-direction: column;
  a {
    display: flex;
    cursor: pointer;
  }
}

.FooterContainer {
  width: 100%;
  height: 60px;
  background: #ffffff;
  background-color: #ffffff;
  box-shadow: 0px -16px 40px rgba(0, 0, 0, 0.1);
  position: sticky;
  transition: 300ms;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.FooterContainerHidden {
  display: none;
}
.FooterWrapper {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  box-shadow: none;
  position: sticky;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .FooterWrapper {
    width: 100vw;
    height: 80px;
    background-color: white;
    box-shadow: 0px -16px 40px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    position: sticky;
    left: 0;
    margin-left: calc(375px - 50vw);
  }
  .FooterContainer {
    width: 687px;
    max-width: 687px;
    height: 80px;
    background: white;
    background-color: white;
    position: relative;
    box-shadow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .RulesFooter {
    margin-left: 0% !important;
  }
}
