.selector {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 20px;
  margin-top: 20px;
  width: auto;
  max-width: 118px;
  width: 100%;
  cursor: pointer;
}
.selectContainer {
  border-radius: 4px;
  border: 1px dashed #999;
  background: #fff;
  display: flex;
  max-width: 118px;
  width: 100%;
  height: 99px;
}
.label {
  font-size: 14px;
  font-family: Open Sans;
  line-height: 138%;
  letter-spacing: 0.105px;
  color: #0f1619;
}

.selectorBox {
  position: absolute;
  left: 0px;
  bottom: -10px;
  z-index: 999;
  display: flex;
  //   min-width: 534px;
  max-width: 40vw;
  min-width: 500px;
  width: auto;
  height: 207px;
  padding: 30px;
  align-items: center;
  gap: 20px;
  border-radius: 6px;
  border: 1px solid #f8f8f8;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.selectButton {
  border-radius: 0px 3px 3px 0px;
  background: #def3f0;
  width: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    color: #28353c;
    text-align: center;
    /* KS_Overline */
    font-size: 12px;
    font-family: Open Sans;
    line-height: 138%;
    letter-spacing: 0.09px;
    text-transform: uppercase;
    margin-top: 11px;
  }
}

.rotate180 {
  transform: rotate(180deg);
}
.selectIconWrapper {
  display: flex;
  width: 10px;
  height: 10px;
}

.darkGreen {
  background: #6fcf97;
}
.borderGreen {
  border: 1px solid #6fcf97 !important;
}

.selectableIcon {
  width: 59px;
  min-width: 59px;
  height: 59px;
  min-height: 59px;
  border-radius: 59px;
  border: 2px solid #6fcf97 !important;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffffff;
}
.selectableIconWrapper {
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  align-items: center;
  height: 147px;
  flex: 1;
  border-radius: 6px;
  border: 1px solid #f8f8f8;
  flex-direction: column;
  &:hover {
    background: #f8f8f8;
    border: 1px solid #6fcf97 !important;
  }

  span {
    margin-top: 10px;
    color: #000;
    text-align: center;
    font-size: 12px;
    font-family: Open Sans;
    font-weight: 600;
    line-height: 138%;
    letter-spacing: 0.09px;
    text-transform: uppercase;
  }
}

.selected {
  background: #f8f8f8;
  border: 1px solid #6fcf97 !important;
}

.selectedIcon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 40px;
  border: 2px solid #6fcf97 !important;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffffff;
}
