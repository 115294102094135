.contractorLayout {
  background-color: #f8f8f8 !important;
  min-height: 100vh;
  height: 100%;
}
.drawerLayout {
  max-height: 100vh !important;
  overflow: hidden !important;
}
.transitionEnd {
  background-color: #f8f8f8;
  transform: none !important;
}
.wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-width: 0;
  min-height: 100vh;
  position: relative;
  background-color: #f8f8f8 !important;
}
.contractorBody {
  // height: calc(100vh - 78px);
  width: 100%;
  margin: none;
  position: relative;
  filter: none !important;
}

@keyframes slideLeft {
  0% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 50%;
    top: 0px;
  }
}

@media (min-width: 768px) {
  .contractorLayout {
    background-color: #f8f8f8 !important;
  }
  .contractorBody {
    // height: calc(100vh - 78px);
    width: 750px;
    margin: 0 auto;
    position: unset;
    background-color: #f8f8f8 !important;
  }
}
