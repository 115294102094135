.cardBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px 20px;
  width: 100%;
  min-height: 185px;
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-bottom: 20px;
  flex-direction: column;
  cursor: pointer;
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .mainBox {
      width: 100%;
      display: flex;
      flex: 1;
      padding-top: 20px;
      display: flex;
      flex-direction: column;

      .cardTitle {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 138%;
        /* identical to box height, or 22px */

        display: flex;
        align-items: center;
        letter-spacing: 0.0075em;

        /* E26C82 */

        color: #e26c82;
      }
      .cardIconWrapper {
        width: 50px;
        height: 50px;
        min-height: 50px;
        float: left;
        border: 1px solid #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      .cardMessage {
        margin-top: 10px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 138%;
        /* or 19px */

        display: flex;
        align-items: center;
        letter-spacing: 0.0075em;

        /* KS_28353C */

        color: #28353c;
      }
      .mainImage {
        border-radius: 4px 4px 0px 0px;
        width: 100%;
        margin-top: 13px;
        margin-bottom: 13px;
        max-height: 150px;
        object-fit: cover;
      }
    }
    .dragBox {
      min-width: 74px;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-top: 30px;
      img {
        cursor: pointer;
        opacity: 0.5;
      }
    }
    .actionBox {
      justify-content: center;
      align-items: center;
      display: flex;
      margin-top: 30px;
      position: relative;
      .popOver {
        width: 143px;
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
        position: absolute;
        right: 10px;
        bottom: -130px;
        display: flex;
        align-items: center;
        background-color: white;

        cursor: pointer;
        flex-direction: column;
        .popOverItem {
          display: flex;
          justify-content: flex-start;
          padding-left: 20px;
          padding-right: 20px;
          align-items: center;
          box-shadow: 0px 1px 0px 0px #f2f2f2;
          background-color: white;
          width: 100%;
          height: 41px;
          margin-bottom: 1px;
          svg {
            margin-right: 20px;
          }
          &:first-child {
            margin-bottom: 0px !important;
            border-radius: 6px 6px 0px 0px;
          }
          &:last-child {
            margin-bottom: 0px !important;
            border-radius: 0px 0px 6px 6px;
          }
          &:hover {
            background-color: #f8f8f8;
          }
        }

        span {
          color: #28353c;
          /* Body regular OpenSans */
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 138%; /* 19.32px */
          letter-spacing: 0.105px;
        }
      }
      .actionButton {
        width: 34px;
        height: 34px;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        display: flex;
        opacity: 0.5;
        cursor: pointer;
        &:hover {
          background: linear-gradient(135deg, #6fcf97 0%, #5eca8b 100%);
          color: #ffffff !important;
          path {
            fill: #ffffff !important;
          }
        }
      }
    }
  }

  .wrapper:hover .DragIcon,
  .wrapper:hover .actionButton {
    opacity: 1;
    transition-duration: 300ms;
  }
}

.z100 {
  z-index: 1 !important;
  border: 2px solid #ffffff;
}

.borderYellow {
  border: 2px solid #f2c75e !important;
}
.borderRed {
  border: 2px solid #e26c82 !important;
}

.borderYellowIdle,
.borderRedIdle {
  border: 2px solid #fff !important;
  transition-duration: 300ms;
}
.borderYellowIdle:hover {
  border: 2px solid #f2c75e !important;
}

.borderRedIdle:hover {
  border: 2px solid #e26c82 !important;
}

.red {
  color: #e26c82 !important;
}
.yellow {
  border: #f2c75e !important;
}

.informationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  transition-duration: 300ms;
  opacity: 0;
}

.wrapper:hover .informationWrapper {
  opacity: 1;
}
.isSelectedInformation {
  opacity: 1 !important;
}

.siteCountWrapper {
  display: flex;
  flex-direction: row;
  .siteCountTitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    /* identical to box height, or 22px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;

    /* E26C82 */

    color: #3c4d56;
  }

  .siteCount {
    width: 35px;
    height: 27px;
    background-color: #3c4d56;
    margin-left: 10px;
    /* identical to box height, or 22px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;
    justify-content: center;
    align-items: center;
    border-radius: 27px;

    /* E26C82 */
    .siteCountLength {
      color: #ffffff !important;
      font-family: "Open Sans" !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
  }
}
