.Container {
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px auto;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
}
.TextContainer {
  display: flex;
  flex-direction: column;
  margin: 0px auto 20px auto;
  width: 90%;
}

.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }

  &:focus {
    text-decoration: none !important;
  }
}
.VRContainer {
  width: 100%;
  height: auto;
  margin-top: 20px;
  position: relative;
}
.Image {
  width: 100%;
  height: 100%;
}
.ButtonContainer {
  width: 100%;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  margin: 3px auto;
}

.completedWrapper {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0px;
  top: 0px;
  background: #ffffff;
  opacity: 0.9;
  border-radius: 0px 0px 0px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: flex;
    width: 13.96px;
    height: 10.48px;
  }
}
.vrWrapper {
  padding: 10px;
}
