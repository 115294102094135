.MultiCheckbox {
  display: flex;
  flex-direction: row;
}

.checkbox {
  cursor: pointer;
  height: 39px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  display: flex;
  width: auto;
  padding: 10px;
  margin: 10px;
  justify-content: flex-start;
  align-items: center;
  border: none;
  &:focus {
    outline: none;
  }
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* KS_28353C */

    color: #28353c;
    margin-left: 9px;
  }
}
.checked {
  background: #28a745 !important;
}
.circle {
  width: 27px;
  height: 27px;
  background: #f8f8f8;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    width: 80%;
    display: flex;
  }
}
