.buttonContainer {
  position: fixed;
  width: 100%;
  padding-left: 256px;
  height: 40px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
}
.w124 {
  width: 124px !important;
}
.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: calc(50% - 38px) !important;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.selectedButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rightButtonWrapper {
  display: flex;
}
.darkButton {
  display: flex;
  width: 196px;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: linear-gradient(91.05deg, #03ce91 0.16%, #36bc94 100%);
  border: 1px solid #03ce91;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;

  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
  path {
    fill: #ffffff !important;
  }
  .icon {
    padding-left: 10px;
  }
}

.lightButton {
  width: 121px;
  height: 40px;
  background: #474a59;
  border: 1px solid #474a59;
  box-sizing: border-box;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
}
.deleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 40px;
  background: #e26c82;
  border: 1px solid #e26c82;
  box-sizing: border-box;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  margin-left: 5px;
  color: #ffffff;
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
}

.mr100 {
  margin-right: 100px !important;
}

.buttonIcon {
  margin-left: 16px;
}
