@font-face {
  font-family: "Open Sans ExtraBold";
  src: url(./assets/fonts/OpenSans-ExtraBold.ttf) format("truetype");
}

$colors: (
  "c1": #28353c,
  "c2": #ffffff,
);

body {
  background-color: map-get($colors, c2) !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
span {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
p {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
h1 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
h2 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
h3 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
h4 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
h5 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.modal-dialog {
  max-width: 95vw !important;
  max-height: 90vh !important;
  height: 100%;
}

.modal-content {
  height: 100%;
}

.tippy-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #7185be !important;
}
.supervisor-logout {
  z-index: 9999 !important;
}
.permission-nav {
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  z-index: 9999 !important;
}
.c-header.c-header-fixed {
  z-index: 2002 !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

@media print {
  .c-body {
    background: #fff !important;
    margin-top: -60px;
  }

  .c-header,
  .supervisor-logout,
  .c-header-fixed,
  .permission-nav {
    display: none !important;
    opacity: 0 !important;
    background-color: #fff !important;
  }
}

//scroll bar styling
::-webkit-scrollbar {
  width: 10px;
  background: #ffffff;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
//popupjs styling
.popup-content {
  margin: auto;
  background: #28353c !important;
  width: 50%;
  padding: 8px 12px !important;
  border: 1px solid #28353c !important;
  span {
    text-align: center;
    font-family: "Open Sans" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* Global/09. White */

    color: #ffffff !important;
  }
}
.popup-arrow {
  color: #28353c !important;
}

[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: #28353c;
  z-index: 999999999999 !important;
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.flex-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.react-tooltip-keepsafe {
  background-color: #28353c !important;
  color: #ffffff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%; /* 19.32px */
  letter-spacing: 0.105px;
  max-width: 250px;
  padding: 15px;
  z-index: 99999999999;
  opacity: 1;
}

.w-full {
  width: 100%;
}

.progress-bar {
  background: var(
    --lime-gradient,
    linear-gradient(135deg, #6fcf97 0%, #5eca8b 100%)
  );
}
body:has(.requires-no-scroll) {
  overflow: hidden !important;
}

.c-header-fixed {
  z-index: 9999999999 !important;
}

.container-fluid:has(.no-right-padding) {
  padding-right: 0px !important;
}
