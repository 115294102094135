.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 80vh;
  width: 70vw;
}
