.dashboardSection2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding-left: 23px;
  padding-right: 23px;
}
.language {
  height: 60px;
  display: flex;
  width: 100%;
  padding: 20;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.topStats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9ebec;
}

.divider {
  width: 2px;
  height: 29px;
  margin-left: 25px;
  margin-top: 15px;
}
.firstDivider {
  width: 2px;
  height: 29px;
  margin-top: 15px;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.countSection {
  display: flex;
  flex-direction: column;
  margin-left: 26px;
}

.count {
  font-family: Open Sans Condensed;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 138%;
  /* or 55px */

  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}
.weeklyStats {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.dayWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 21px;
  align-items: center;
  justify-content: flex-start;
}
.day {
  display: flex;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  min-width: 90px;
  /* or 19px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.progressContainer {
  width: 100%;
  position: relative;
}

.progressWrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 2px;
  background: #e9ebec;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 4px;
}

.progress {
  background: #d54250;
  opacity: 0.5;
  border-radius: 0px 0px 10px 10px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.hover {
  position: absolute;
  top: -35px;
  right: 30px;
  width: 170px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-family: Open Sans;
  background: #ffffff;
  border: 0.5px solid #dddddd;
  box-sizing: border-box;
  border-radius: 6px;
  z-index: 6;
}
.arrowDown {
  width: 4px;
  height: 4px;
  border-bottom: 0.5px solid #dddddd;
  border-right: 0.5px solid #dddddd;
  position: absolute;
  background-color: white;
  transform: rotate(45deg);
  z-index: 5;
  bottom: -3px;
  left: calc(50% - 5px);
}

.checkinText {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 138%;
  /* identical to box height, or 14px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 007BFF */

  color: #007bff;
}

.completedText {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 138%;
  /* identical to box height, or 14px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 03CE91 */

  color: #03ce91;
}

.registeredText {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 138%;
  /* identical to box height, or 17px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* BB6BD9 */

  color: #bb6bd9;
}

@media (max-width: 1250px) {
  .count {
    font-family: Open Sans Condensed;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 138%;
    /* or 55px */

    letter-spacing: 0.0075em;

    /* KS_28353C */

    color: #28353c;
  }
  .dashboardSection2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 2;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }

  .countSection {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 138%;
    /* or 19px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;

    /* 40507E */

    color: #40507e;
  }
}
