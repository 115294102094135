.container {
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
}

.box {
  display: flex;
  width: 463px;
  padding-bottom: 50px;
  height: auto;
  background: #ffffff;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  flex-direction: column;
}
.logo {
  width: 100px;
  height: auto;
  margin-top: 20px;
  margin-left: 20px;
  align-self: flex-start;
  display: flex;
}

.button {
  margin-top: 55px;
  width: 100%;
  height: 48px;
  left: 547px;
  top: 553px;
  background: #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  text-decoration: none;
  outline: none;
  border: none;
  &:hover {
    text-decoration: none;
    background: #7185be;
    color: #ffffff;
    outline: none;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

.title {
  display: flex;
  margin-top: 32px;
  margin-bottom: 18px;
  align-self: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  /* identical to box height, or 28px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}
.desc {
  margin-bottom: 9px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}
.info {
  margin-bottom: 25px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 17px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.succesIcon {
  display: flex;
  width: 53px;
  height: 39px;
  align-self: center;
  margin-top: 64px;
  margin-bottom: 34px;
}

.wrapper {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}
