.Container {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  opacity: 1;
}
.ContainerHidden {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  opacity: 0;
}
.logoWrapper {
  width: 109px;
  height: 109px;
  background: #ffffff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  animation: glow 1s ease-in-out infinite alternate;
  display: flex;
  img {
    display: flex;
    width: 109px;
    height: 109px;
  }
}

@keyframes glow {
  from {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  }

  to {
    box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.1);
  }
}
