.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 80vh;
  width: 70vw;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.buttonContainer {
  position: fixed;
  width: 100%;
  padding-left: 256px;
  height: 66px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
}
.lightButton {
  width: 121px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #7185be;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #7185be;
  margin-right: 20px;
  &:hover {
    background: #7185be;
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
}

.darkButton {
  width: 121px;
  height: 40px;
  left: 1197px;
  background: #40507e;
  border: 1px solid #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: #40507e;
  }
  &:focus {
    outline: none;
  }
}
.flex {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.permissionsList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  overflow: scroll;
  width: 100%;
  height: 100%;
}

.permissionsItem {
  display: flex;
  flex-direction: row;
  height: 49px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #f6f7fa;
}
.permissionName {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  margin-left: 26px;
  letter-spacing: 0.0075em;
  padding: 5px;
  color: #40507e;
  width: 100%;
}

.selectedPermissionsItem {
  display: flex;
  flex-direction: row;
  height: 49px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background: #f6f7fa;
}

.selectedContainer {
  display: flex;
  flex: 1;
  background: #f6f7fa;
  border-radius: 6px;
  width: 100%;
  flex-direction: column;
  margin: 20px;
  position: relative;
}
.selectedItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 50px;
}
.selectedTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #40507e;
  margin: 20px;
}
.closeIconContainer {
  position: absolute;
  top: 24px;
  right: 24px;
}

.descriptionText {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.selectItemTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.selectItemDescription {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 17px */

  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #4d4d50;
}

.selectedItemActionTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */
  display: flex;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}
.fullAccessBadge {
  background: #ffffff;
  border-radius: 10px;
  width: 80px;
  height: 27px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-right: 30%;
  span {
    display: flex;
    align-self: center;
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: #40507e;
    width: 80px;
  }
}
.restoreButton {
  cursor: pointer;
  width: 139px;
  height: 27px;
  left: 1139px;
  top: 669px;
  justify-content: center;
  background: #ffffff;
  border: 0.5px solid #7185be;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 138%;
    /* identical to box height, or 17px */

    letter-spacing: 0.0075em;

    /* 40507E */

    color: #40507e;
  }
}
.paginationContainer {
  background: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
  height: 35px;
  width: calc(100% - 256px);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  span {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(165, 180, 201, 0.2);
    }
  }
}
