@import "../../../styles/variables.scss";

.Container {
  height: 100%;
  width: 100%;
  margin: auto;
  top: 0;
  left: 0;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  position: relative;
  // transform: translateY(-140px);
  &:before {
    display: inline-block;
    content: "";
    position: relative;
    height: 100%;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #ffffff 21.96%,
      rgba(255, 255, 255, 0) 100%
    );
    flex: 1 1;
    height: 100%;
  }
}
.image {
  flex: 1;
  height: calc(100vh - 82px);
}
.ImageWrapperWithoutText {
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  position: absolute;
  > div {
    width: 100% !important;
    height: 100% !important;
    > div {
      height: 100% !important;
      justify-content: center;
      display: flex;
      flex-direction: row;
      overflow: hidden;
    }
  }
}
.ImageWrapper {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;

  > div {
    width: 100% !important;
    height: 100% !important;
    > div {
      height: 100% !important;
      &:before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          #ffffff 21.96%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }
}
.completed {
  height: calc(100% - 3.75rem);
}

.TextContent {
  position: absolute;
  top: 7.3125rem;
  margin: 0 auto;
  margin-bottom: 2.875rem;
  padding: 0 2rem;
  pointer-events: none;
}
.TitleWrapper {
  color: map-get($colors, c6);
  font-size: 1.375rem;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  text-align: center;
  letter-spacing: 0.0075em;
  line-height: 1.875rem;
  margin-bottom: 0.8125rem;
  text-align: left;
}
.ContentWrapper {
  color: map-get($colors, c6);
  font-family: "Open Sans";
  font-size: 0.875rem;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: map-get($colors, c1);
}
.IconContainer {
  width: 4rem;
  height: 4rem;
  /*box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.IconWrapper {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: map-get($colors, c1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: relative;
}
.Icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.rotateIconWrapper {
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 10;
  vertical-align: middle;
  text-align: center;
  padding-top: 0.7rem;
  border-radius: 50%;
  background-color: #fff;
  right: 20px;
  top: -20%;
}
.Halo {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.HaloChecked {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #6fcf97;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.IconWrapper .miniCheckIconWrapper {
  position: absolute;
  top: -1rem;
  right: -0.5rem;
  z-index: 11;
}
.textIconWrapper {
  min-width: 120px;
  padding: 10px;
  background-color: #28353c;
  color: #fff;
  border-radius: 6px;
  z-index: 10;
  position: relative;
}
.textIconWrapper .miniCheckIconWrapper {
  position: absolute;
  top: -12px;
  right: -4px;
  z-index: 11;
}
.rectangleHalo {
  width: 100%;
  min-width: 140px;
  height: 100%;
  opacity: 0.5;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: -38px;
  border-radius: 6px;
  z-index: 5;
}
.rectangleHaloChecked {
  width: 100%;
  min-width: 140px;
  height: 100%;
  opacity: 0.5;
  background-color: #6fcf97;
  position: absolute;
  top: 0;
  left: -38px;
  border-radius: 6px;
  z-index: 5;
}

.NotificationWrapperHidden {
  position: fixed;
  opacity: 0;
  transition: 300ms;
  background-color: #28353c;
  color: #fff;
  z-index: 15;
  width: 80%;
  left: 10vw;
  top: -40vh;
  padding: 10px;
  border-radius: 6px;
}

.NotificationWrapper {
  position: fixed;
  opacity: 1;
  transition: 300ms;
  background-color: #28353c;
  color: #fff;
  z-index: 15;
  width: 80%;
  left: 10%;
  top: 42vh;
  padding: 10px;
  border-radius: 6px;
}
.NotificationHeader {
  font-weight: bold;
  display: block;
}

.Notification {
  display: block;
}

.NotificationClose {
  position: absolute;
  top: 0;
  right: 10px;
}

.PanWrapper {
  position: absolute;
  top: -112px;
  z-index: 20;
  left: 40%;
  width: 76px;
  height: 76px;
  text-align: center;
  transition: 300ms;
  opacity: 1;
}

.PanWrapperHidden {
  position: absolute;
  top: -112px;
  z-index: 20;
  left: 40%;
  width: 76px;
  height: 76px;
  text-align: center;
  transition: 300ms;
  transition-delay: 1000ms;
  opacity: 0;
}

.PanIconWrapper {
  z-index: 12;
  position: relative;
  text-align: center;
  height: 56px;
  top: 10px;
  background-color: #fff;
  border-radius: 50%;
  width: 56px;
  left: 10px;
  padding: 6px;
}

.ChevronLeft {
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 100;
}

.ChevronRight {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 100;
}

.ChevronCountLeft {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #28353c;
  color: #fff;
  position: absolute;
  text-align: center;
  top: 2px;
  left: 24px;
}

.ChevronCountRight {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #28353c;
  color: #fff;
  position: absolute;
  text-align: center;
  top: 2px;
  right: 24px;
}

@media only screen and (max-width: 480px) {
  .TextContent {
    top: 8.5625rem;
  }
}

@media only screen and (min-width: 480px) {
  .TextContent {
    top: 12.5rem;
  }
}

@media (min-width: 1024px) {
  .Container {
    width: 750px;
    left: inherit;
  }
}

@media only screen and (max-height: 480px) and (orientation: landscape) {
  .Container {
    display: none;
  }
}
