@import "../../styles/variables";

.Container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.header {
  position: relative;
  z-index: 1;
}
.TextContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
}
.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 4.17232e-9px;
  margin-bottom: 24px;
  color: map-get($colors, c3);
}
.Description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.58946e-9px;
  color: map-get($colors, c8);
}
.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
@media (min-width: 1024px) {
  .Container {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
