@import "../../../styles/_variables.scss";
.Container {
  width: 100%;
  padding: 0 0.9375rem;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  margin-bottom: 5rem;
}
.Header {
  font-size: 1.125rem;
  line-height: 138%;
  padding-top: 2.125rem;
  letter-spacing: 0.0075em;
  margin-bottom: 1.5625rem;
  color: map-get($colors, c6);
}

.ImageContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2rem;
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    opacity: 0.999;
    height: 1px;
  }
}
.FlipCard {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  // border-radius: 6px;
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  // // -webkit-transition: background-image 0.5s ease-out;
  // // transition: background-image 0.5s ease-out;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 6px;
  border: 1px solid #dddddd;
  background: #ffffff;
  width: 100%;
  position: block;
}

.FlipHeader {
  height: 48px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: linear-gradient(
    180deg,
    map-get($colors, c21) 0%,
    map-get($colors, c1) 100%
  );
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px 6px 45px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9px 15px;
  font-size: 0.875rem;
  line-height: 138%;
  align-items: center;
  letter-spacing: 0.0075em;
  color: map-get($colors, c5);
}
.Input {
  opacity: 0 !important;
  &:checked ~ .Wrap3D .Card3DWrap {
    transform: rotateY(180deg);
    transition: transform 300ms 100ms ease-out;
  }
}

.Content {
  font-size: 0.875rem;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: map-get($colors, c6);
  margin-bottom: 1.25rem;
}
.FlipIconWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  align-self: flex-end;
  padding: 0.5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  svg {
    font-size: 10px;
  }
  path {
    &:hover {
      fill: #44c032;
    }
  }
  &:hover {
    .IconBackground {
      opacity: 0.9;
      border: 1px solid #44c032;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 44px;
    }
  }
}
.IconBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  background: #ffffff;
  border: 1px solid #44c032;

  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
.cardTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 138%;
  /* or 33px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_28353C */
  margin-bottom: 17px;
  color: #28353c;
}

.cardDescription {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
}
.CardContent {
  font-size: 12px;
  line-height: 138%;
  transition: transform 1s;
  transform-style: preserve-3d;
  letter-spacing: 0.0075em;
  color: map-get($colors, c1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
  height: 75%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  align-items: center;
  backface-visibility: hidden;
}
mark {
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 225, 0, 0.1),
    rgba(255, 225, 0, 0.7) 4%,
    rgba(255, 225, 0, 0.3)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

@media (min-width: 768px) {
  .Image {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
}

.Wrapper {
  height: 40vh;
  height: 40vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.125rem;
}

.Wrap3D {
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  perspective: 1000px;
  width: 100%;
  height: 100%;
}
.Card3DWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: transform 200ms 100ms ease-out;
}
.Front,
.Back {
  width: 100%;
  height: 100%;
  min-height: 108px;
  position: absolute;
  border-radius: 6px;
  left: 0;
  top: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 6px;
}
.Back {
  transform: rotateY(180deg);
}

.ContentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 6px;
}
.Image {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.isCompletedCheckWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  background: #ffffff;
  opacity: 0.9;
  border-radius: 0px 6px;
  top: 0;
  right: 0;
  z-index: 9;
  transition: 300ms;
}
.hidden {
  opacity: 0 !important;
  transition: 0 !important;
}
