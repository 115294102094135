.detailContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.detailWrapper {
  display: flex;
  flex: 1;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
}

.detailSection2 {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.detailContent {
  display: flex;
  flex: 1;
  margin: 10px;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-direction: column;
}

.progressSuccess {
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 20px;
  position: relative;
}
.progressFail {
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 20px;
  position: relative;
}
.progressBlank {
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background-color: #c8ced3;
  position: relative;
  z-index: 2;
}
.progressSuccessImage {
  display: flex;
  height: 50px;
  margin-left: -10px;
  margin-bottom: -10px;
  z-index: 2;
}
.progressFailImage {
  display: flex;
  height: 28px;
  margin-left: -4px;
  margin-right: 5px;
  z-index: 2;
}
.progressBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px;
}

.detailInfoContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.detailTitle {
  margin: 10px;
  font-size: 25px;
  padding: 20px;
  font-family: Open Sans;
  font-weight: bold;
}

.detailName {
  margin: 10px;
  font-size: 25px;
  font-family: Open Sans;
  font-weight: bold;
}
.detailSubTitle {
  font-weight: 500;
  font-family: Open Sans;
  font-size: 17px;
  margin-left: 15px;
  display: flex;
  align-self: flex-start;
}
.detailText {
  font-weight: 500;
  font-family: Open Sans;
  font-size: 17px;
  margin-left: 15px;
  display: flex;
  align-self: flex-start;
}
.cardReceivedText {
  font-weight: bold;
  font-family: Open Sans;
  font-size: 17px;
  margin: 15px;
}
.inductionCompleteText {
  font-weight: bold;
  font-family: Open Sans;
  font-size: 17px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.inductionSiteText {
  font-weight: 400;
  font-family: Open Sans;
  font-size: 13px;
  margin-left: 15px;
}
.cardsReceivedProgressBar {
  margin-left: 15px;
  width: 80px;
  height: 30px;
  background-color: #4dbd74;
}
.detailInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}
.slug {
  position: absolute;
  top: -30px;
  left: 5px;
  width: 200px;
  font-weight: 600;
  font-size: 15px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  font-size: 15px;
  background-color: #fafafa;
  color: #1d1d1d;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fafafa transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
