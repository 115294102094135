.formWrapper {
  width: 80%;
  margin: 0px auto;
}
.buttonContainer {
  position: fixed;
  width: 100%;
  padding-left: 256px;
  height: 66px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
}
.lightButton {
  width: 121px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #7185be;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #7185be;
  margin-right: 20px;
  &:hover {
    background: #7185be;
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
}

.darkButton {
  width: 121px;
  height: 40px;
  left: 1197px;
  background: #40507e;
  border: 1px solid #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: #40507e;
  }
  &:focus {
    outline: none;
  }
}

.flex {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
