.section {
  width: 80%;
  align-self: center;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tableHeaderContainer {
  position: relative;
  display: flex;
  overflow-x: auto;
}

.headingWrapper {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(165, 180, 201, 0.5);
  padding-bottom: 34px;
  background: #f6f7fa;
  align-items: center;
  justify-content: center;
  .titleHead {
    margin-right: 55px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 138%;
    /* or 33px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;

    /* FRL Deep Ocean Grey */

    color: #303539;
  }
}

.tableHead {
  width: 100%;
  position: relative !important;
  display: flex;
  overflow-x: auto;
}

.tableHeaderWrapper {
  width: 100%;
  position: relative;
  flex-direction: row;
  display: flex;
}

.tableHeaderElement {
  vertical-align: middle;
  cursor: pointer;
  border-bottom: 2px solid;
  border-bottom-color: #d8dbe0;
  padding: 0.75rem;
  width: auto;
  justify-content: center;
  align-items: center;
  padding-right: 1.5rem !important;
  flex-direction: row;
}
.tableHeaderText {
  font-size: 15px;
}
.tableBody {
  td {
    vertical-align: middle !important;
    height: 81px !important;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  background-color: #fafafa;
  color: #1d1d1d;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  font-family: Open Sans;
  z-index: 1;
  top: 125%;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fafafa transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1280px) {
  .tableHeaderText {
    font-size: 12px;
  }
}

.tableWrapper {
  width: 100%;
  margin: 27px auto;
  table {
    width: 100% !important;
  }
}
.paginationContainer {
  background: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
  height: 35px;
  width: calc(100% - 256px);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  span {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(165, 180, 201, 0.2);
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 250px;
}

.backIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 15px;
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  text-align: center;
  display: flex;
  /* or 30px */

  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #7185be;
}
.heading {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.indicatorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  justify-self: flex-end;
  .indicatorItem {
    margin-left: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 8px;
    }
    span {
      display: flex;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.0075em;
      color: #40507e;
    }
  }
}

//new styles

//image placeholder starts

.imageWrapper {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 2px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  position: relative;

  .statusIndicator {
    position: absolute;
    display: flex;
    width: 13px;
    height: 13px;
    border-radius: 13px;
    background: #cccccc;
    border: 2px solid #f2f2f2;
    top: 0px;
    right: 0px;
  }
}

//image placeholder ends

//status indicator start
.statusWrapper {
  position: relative;
  padding: 5px 10px 5px 5px;
  border: 2px solid;
  border-color: transparent;
  border-radius: 6px;
  display: flex;

  .statusHoverWrapper {
    gap: 10px;
    width: 420px;
    height: auto;
    border-radius: 6px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 0px;
    top: 35px;
    display: flex;
    flex-direction: column;
    background: white;
    z-index: 99999;
    padding: 20px;
    animation: easein 0.2s ease-in;
  }

  .disqualifiedWarningWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 10px;
    img {
      display: flex;
      width: 28px;
      height: 28px;
      margin-right: 25px;
    }
    span {
      color: #e26c82;
      font-feature-settings: "clig" off, "liga" off;
      /* KS_BodyMedium */
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.105px;
    }
  }

  .statusShow {
    display: flex;
    padding: 2px 7px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: linear-gradient(135deg, #6fcf97 0%, #5eca8b 100%);
    margin-bottom: 7px;
    min-height: 21px;
    span {
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      /* KS_Caption */
      font-family: Open Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%; /* 16.56px */
      letter-spacing: 0.09px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  .iconWrapper {
    top: -10px;
    position: absolute;
    right: -10px;
    min-width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 2px solid transparent;
  }

  .iconWrapperHover {
    border: 2px solid #ffffff;
    min-width: 20px;
    height: 20px;
    border-radius: 20px;
    top: -10px;
    right: -10px;
  }

  .statusInfoTitle {
    width: 200px;
    color: #28353c;
    font-feature-settings: "clig" off, "liga" off;
    /* KS_BodyMedium */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.105px;
    position: relative;
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }

  .statusInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    .statusInfoText {
      color: #28353c;
      font-feature-settings: "clig" off, "liga" off;
      /* KS_BodyRegular */
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%; /* 19.32px */
      letter-spacing: 0.105px;
      display: flex;
      flex: 1;
    }
    .statusInfoJoinedText {
      color: #28353c;
      font-feature-settings: "clig" off, "liga" off;
      /* KS_BodyRegular */
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%; /* 19.32px */
      letter-spacing: 0.105px;
      display: flex;
      flex: 2;
    }
  }
  .statusGeneralInductionWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    .progressStatusText {
      color: #28353c;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Open Sans;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 138%; /* 16.56px */
      letter-spacing: 0.09px;
    }
    .progressBar {
      display: flex;
      width: 140px;
      height: 4px !important;
      margin-bottom: 8px;
      margin-top: 10px;
      align-items: flex-start;
      border-radius: 10px;
      background: linear-gradient(135deg, #6fcf97 0%, #5eca8b 100%);
      margin-right: 7px;
    }
    .progressWrapper {
      display: flex;
      flex-direction: column;
    }
  }

  .progressPercentage {
    display: flex;
    padding: 2px 7px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: linear-gradient(135deg, #6fcf97 0%, #5eca8b 100%);
    min-height: 21px;
    span {
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      /* KS_Caption */
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%; /* 16.56px */
      letter-spacing: 0.09px;
      padding-top: 2px;
      padding-bottom: 2px;
      white-space: nowrap;
    }
  }
  .iconWrapper {
    top: -10px;
    position: absolute;
    right: -10px;
    min-width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 2px solid transparent;
  }

  .iconWrapperHover {
    border: 2px solid #ffffff;
    min-width: 20px;
    height: 20px;
    border-radius: 20px;
    top: -10px;
    right: -10px;
  }
}
//status indicator end

//filters start
.filterContainer {
  display: flex;
  flex-direction: row;
  padding-top: 17px;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;
}

.filterWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
//filters end
.recentActivityWrapper {
  min-width: 250px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  flex: 1;
}

//recent activity starts

.recentActivityTitle {
  span {
    color: #28353c;
    font-feature-settings: "clig" off, "liga" off;
    /* KS_BodyMedium */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.105px;
  }
}

.recentActivityInfo {
  color: #666;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 138%; /* 16.56px */
  letter-spacing: 0.09px;
}

.companyCell {
  display: flex;
  .companyText {
    color: #28353c;
    font-feature-settings: "clig" off, "liga" off;
    /* KS_BodyRegular */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.105px;
    width: 120px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
.personaCellWrapper {
  display: flex;
  flex-direction: column;
  .persona {
    color: #28353c;
    font-feature-settings: "clig" off, "liga" off;
    /* KS_BodyRegular */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.105px;
  }
  .role {
    color: #666;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Open Sans;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 138%; /* 16.56px */
    letter-spacing: 0.09px;
    width: 120px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

.peopleStats {
  display: flex;
  flex-direction: column;
  max-width: 78px;
  margin-right: 50px;
  justify-content: center;
  align-items: center;
  .peopleCount {
    color: var(--03-ce-91, #03ce91);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Open Sans Condensed;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 30px */
    letter-spacing: 0.225px;
    margin-bottom: 10px;
  }
  .peopleText {
    color: var(--4-c-4-f-5-f, #4c4f5f);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.105px;
  }
}

.dateRangeWrapper {
  display: flex;
  justify-content: flex-end;
  width: -webkit-fill-available;
}

.failedIconWrapper {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.failedFlag {
  position: absolute;
  top: -20px;
  left: -5px;
}

.flexRow {
  display: flex;
  min-width: 120px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

.failedAttemptText {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  .attempts {
    color: var(--ks-28353-c, #28353c);
    font-feature-settings: "clig" off, "liga" off;
    /* KS_Caption */
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 16.56px */
    letter-spacing: 0.09px;
  }
  .name {
    color: var(--ks-28353-c, #28353c);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 138%; /* 16.56px */
    letter-spacing: 0.09px;
  }
}

.statusTypeCount {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: var(--ks-28353-c, #28353c);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 138%; /* 16.56px */
    letter-spacing: 0.09px;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.statusTypeCountHover {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: var(--ksfff, #fff);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 138%; /* 16.56px */
    letter-spacing: 0.09px;
    padding: 2px;
    padding-right: 5px;
    padding-left: 5px;
  }
}

@keyframes easein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.green {
  color: #44c032 !important;
}

.mainActionButton {
  border-radius: 6px;
  border: 1px solid var(--ksddd, #ddd);
  background: var(--ksfff, #fff);
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 52px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    border-radius: 6px;
    border: 1px solid var(--ksddd, #ddd);
    background: var(
      --lime-gradient,
      linear-gradient(135deg, #6fcf97 0%, #5eca8b 100%)
    );
    svg {
      fill: #ffffff !important;
    }
    path {
      fill: #ffffff !important;
    }
  }
}

.w120 {
  width: 120px;
}
.wFull {
  width: 100%;
}

.divider {
  background: var(--f-2-f-2-f-2, #f2f2f2);
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
}
