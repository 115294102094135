.selectbox {
  .selectbox_head {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-width: 150px;
    align-items: center;
    outline: none;
    border: none;
    &:focus {
      outline: none;
    }
    &:hover {
      outline: none;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      font-family: Open Sans;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
      white-space: nowrap;
      margin-right: 6px;
    }
    svg {
      margin-left: auto;
    }
  }

  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.0075em;

    /* FRL Deep Ocean Grey */

    color: #303539;
  }

  .filtericon {
    float: right;
  }

  .dropdown {
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    position: absolute;
    top: calc(100% + 8px);
    left: 0px;
    right: 0px;
    display: none;
    z-index: 99999999;
    background: white;
    width: 100%;
    max-width: auto;

    &.open {
      display: block;
    }

    h6 {
      font-weight: bold;
      font-size: 14px;
      line-height: 120%;
      padding: 16px 8px 8px 8px;
      color: #1f252c;
    }

    ul {
      padding: 0px 0px 0px 0px;
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;

      & li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 15px 8px 15px;
        &:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
        &:hover {
          background: #fafafa;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding-left: 8px;
          min-height: 35px;
          cursor: pointer;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 138%;
          /* identical to box height, or 17px */

          letter-spacing: 0.0075em;

          /* KS_28353C */

          color: #28353c;

          svg {
            margin-right: 8px;
          }
        }

        &:last-child {
          margin-bottom: 0px;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
}

.rotate {
  transform: rotate(180deg);
}

.indicatorItem {
  margin-left: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 8px;
  }
  span {
    display: flex;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.0075em;
    color: #40507e;
  }
}

.counterLabel {
  position: absolute;
  width: 18px;
  height: 15px;
  background-color: #303539;
  border-radius: 13px;
  color: white;
  top: -15px;
  right: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}
