.dashboardContainer {
  width: 100%;
  margin: 0 auto;
}
.dashboardWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.flexCol {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}
.flex {
  display: flex;
  width: 100%;
}
.col {
  flex-direction: column;
}
.ml10 {
  margin-left: 10px;
}

.dashboardSection1 {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  flex: 3.5;
  margin: 10px;
}
.dashboardSection2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;
  margin: 20px 20px 50px 20px;
  -webkit-box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.1);
}
.countContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}

.countDiv {
  display: flex;
  height: 200px;
  width: 100%;
  border-radius: 15px;
  -webkit-box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.1);
  margin: 10px;
}
.countSection {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  span {
    text-align: center;
    font-size: 20px;
    font-family: Open Sans;
    font-weight: bold;
  }
}
.countSection2 {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  span {
    font-size: 45px;
    font-family: Open Sans;
    font-weight: bold;
  }
}
.progressColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.progressRow {
  height: 170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
}
.progressItem {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.progressItemSection1 {
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: flex-start;
  p {
    width: 80%;
    margin: 10px;
    font-size: 14px;
    font-weight: bold;
    font-family: Open Sans;
  }
}
.progressItemSection2 {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: relative;
  span {
    font-size: 45px;
    font-weight: bold;
    font-family: Open Sans;
  }
  p {
    font-size: 25px;
    font-weight: bold;
    font-family: Open Sans;
  }
}
.progressIcon {
  margin: 5px;
  width: 80px;
  border-radius: 100px;
  border: 7px solid;
}

.recentActivityHeaderContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 36px;
  border-bottom: 1px solid #edf2f6;
  span {
    width: 100%;
    float: right;
    text-align: start;
    margin: 5px;
    font-size: 0.875rem;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    color: #2d3d5c;
    letter-spacing: 0;
  }
  p {
    float: left;
    text-align: end;
    width: 100%;
    margin: 5px;
    font-size: 0.875rem;
    color: #20a8d8;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 0;
  }
}

.recentActivityItemWrapper {
  display: flex;
  width: 100%;
  height: 100px;
  &:nth-child(2n) {
    background-color: #edf2f6;
  }
}

.recentActivityItemDescriptionWrapper {
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.itemUserName {
  font-size: 16px;
  font-weight: bold;
  font-family: Open Sans;
}
.emailIcon {
  margin-right: 5px;
}
.itemStatusWrapper {
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.itemDaysPassedItem {
  font-size: 12px;
  font-family: Open Sans;
}
.itemDay {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.itemStatus {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.userIcon {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 20px;
  width: 40px;
  height: 40px;
}
.itemContentWrapper {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.recentActivityContainer {
  //   &:nth-child(2n) {
  //     background-color: red;
  //   }
}

.progressWrapper {
  height: 80%;
  display: flex;
  border-bottom: 1px solid #c8ced3;
}
.progressSubDescContainer {
  width: 100%;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 300;
    font-family: Open Sans;
  }
  b {
    font-weight: 600;
  }
}
.passRate {
  position: absolute;
  font-size: 10px !important;
  top: 20px;
  font-family: Open Sans !important;
  font-weight: 300 !important;
  left: 0px;
}
.midTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  /* identical to box height, or 28px */
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
}
.midTitleRight {
  display: flex;
  align-self: flex-start;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;

  margin-block-start: 0px;
  margin-block-end: 0px;
  /* identical to box height, or 28px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
}

.filterButton {
  display: flex;
}

.headWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 10px 0px 10px 0px;
}

@media (max-width: 1250px) {
  .ml10 {
    margin-left: unset;
  }
  .emailText {
    font-size: 10px;
  }
  .progressItemSection2 {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      font-size: 40px;
      font-weight: bold;
      font-family: Open Sans;
    }
    p {
      font-size: 25px;
      font-weight: bold;
      font-family: Open Sans;
    }
  }
  .progressItemSection1 {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: flex-start;
    p {
      width: 80%;
      margin: 10px;
      font-size: 10px;
      font-weight: bold;
      font-family: Open Sans;
    }
  }
  .progressSubDescContainer {
    width: 100%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 300;
      font-family: Open Sans;
      font-size: 10px;
    }
    b {
      font-weight: 600;
    }
  }
}

.topSection {
  border-bottom: 1px solid rgba(165, 180, 201, 0.5);
  padding-bottom: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 138%;
    /* or 33px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;

    /* FRL Deep Ocean Grey */

    color: #303539;
  }
  .inductionSelector {
    cursor: pointer;
    width: 250px;
  }
}

@media (max-width: 1250px) {
  .topSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .inductionSelector {
      width: 100%;
    }
  }
  .dashboardWrapper {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    padding-bottom: 200px;
  }
  .progressRow {
    height: 170px;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    flex: 1;
    margin-top: 10px;
  }
  .countContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .passRate {
    position: absolute;
    font-size: 10px !important;
    top: 10px;
    font-family: Open Sans !important;
    font-weight: 300 !important;
    left: 0px;
  }
  .progressItemSection2 {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      font-size: 40px;
      font-weight: bold;
      font-family: Open Sans;
    }
    p {
      font-size: 25px;
      font-weight: bold;
      font-family: Open Sans;
    }
  }
  .flexCol {
    display: flex;
    flex-direction: column !important;
    width: 100%;
    position: relative;
  }
}
