.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  /*animation: fadeIn 1s;*/
  padding-bottom: 150px;
  position: relative;
  // height: calc(100vh - 215px);
  // overflow-y: scroll;
  background-color: #ffffff;
  padding-bottom: 400px;
}

.mainTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  /*position: sticky;*/
  top: 215px;
  min-height: 55px;
  z-index: 99999999999;
  left: 0;
  .mainTitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */
    letter-spacing: 0.0075em;
    color: #000000;
    padding: 18px;
  }
  img {
    margin-right: 10px;
    cursor: pointer;
  }
}
.infoWrapper {
  /*position: sticky;*/
  top: 270px;
  z-index: 9888888889999;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  padding-top: 18px;
  padding-bottom: 18px;

  .miniDivider {
    width: 34px;
    height: 0px;

    /* E0E0E0 */

    border: 1px solid #e0e0e0;
    margin: 25px;
  }

  .infoElement {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
    &:hover {
      background: var(--ks-light-green, #def3f0);
      .iconWrapper {
        background-color: white;
      }
    }
    &:focus {
      background: var(--dddddd, #ddd);
      .iconWrapper {
        background-color: white;
      }
    }
    .iconWrapper {
      width: 28px;
      height: 28px;
      border-radius: 28px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        display: flex;
      }
    }
    span {
      display: flex;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 138%;
      /* identical to box height, or 17px */

      letter-spacing: 0.0075em;

      /* KS_28353C */

      color: #28353c;
    }
    .siteCount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 7px;

      width: 28px;
      height: 17px;

      /* 666666 */

      background: #666666;
      border-radius: 100px;
      margin-left: 8px;

      span {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 138%;
        /* identical to box height, or 17px */

        display: flex;
        align-items: center;
        letter-spacing: 0.0075em;

        /* KS_FFF */

        color: #ffffff;
      }
    }
  }
}
.titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative;
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 138%;
    /* identical to box height, or 32px */

    letter-spacing: 0.0075em;

    color: #000000;
  }
  .siteTooltip {
    position: absolute;
    right: 20px;
    top: 33.37;
  }
}

.formAreaContainer {
  margin-top: 1px;
  background-color: #ffffff;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.siteCountTitle {
  width: 37px;
  height: 28px;
  margin-left: 10px;

  /* Lime gradient */

  background: linear-gradient(97.42deg, #6fcf97 0%, #5eca8b 100%);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 138%;
    /* identical to box height, or 28px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;

    /* KS_FFF */

    color: #ffffff;
  }
}

.characterizationFormArea {
  margin-left: 20px;
  margin-right: 20px;
  /* F8F8F8 */

  background: #f8f8f8;
  /* Mango */

  border: 2px solid #f4bb44;
  border-radius: 10px;
  padding: 10px 20px 30px;

  label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #0f1619;
    margin-left: 0px;
  }
  input {
    background: #ffffff;
    border-radius: 4px;
    border: 2px solid #6fcf97;
  }
  textArea {
    background: #ffffff;
    border-radius: 4px;
    border: 2px solid #6fcf97;
  }
}

.checkboxWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectBoxWrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.informationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.siteCountWrapper {
  display: flex;
  flex-direction: row;
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138%;
    /* identical to box height, or 22px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;

    /* E26C82 */

    color: #3c4d56;
  }

  .siteCount2 {
    width: 35px;
    height: 27px;
    background-color: #3c4d56;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-left: 10px;
    /* identical to box height, or 22px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;
    justify-content: center;
    align-items: center;
    border-radius: 27px;

    /* E26C82 */

    color: #ffffff;
  }
}

.borderRed {
  border: 2px solid #e26c82 !important;
}

.siteDragWrapper {
  display: flex;
  justify-content: space-between;

  .siteDragBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 350px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 15px;
    // overflow-y: scroll;
  }
  .siteSearchInputWrapper {
    display: flex;
    width: 100%;
    position: relative;
    input {
      padding-left: 35px;
      width: 100%;
      border-radius: 4px 4px 0px 0px;
      background: #a5b4c9;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
      color: #ffffff !important;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%;
      letter-spacing: 0.105px;
      height: 44px;
    }
    input::placeholder {
      color: #fff !important;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%;
      letter-spacing: 0.105px;
    }
    .searchIcon {
      position: absolute;
      left: 12px;
      top: 12px;
      width: 20px;
      height: 20px;
    }
  }
  .controlSection {
    background: #f8f8f8;
    padding: 13px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actionBox {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .popOver {
        width: 187px;
        height: 41px;
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
        position: absolute;
        right: 0px;
        bottom: -45px;
        background-color: white;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
        &:hover {
          background-color: #f8f8f8;
        }
        span {
          color: #e26c82;
          /* Body regular OpenSans */
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 138%; /* 19.32px */
          letter-spacing: 0.105px;
        }
      }
      .actionButton {
        width: 34px;
        height: 34px;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        &:hover {
          background: linear-gradient(135deg, #6fcf97 0%, #5eca8b 100%);
          color: #ffffff !important;
          path {
            fill: #ffffff !important;
          }
        }
      }
    }
  }

  .siteSelectionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    .infoElement {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      img {
        display: flex;

        margin-right: 8px;
      }
      span {
        display: flex;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 138%;
        /* identical to box height, or 17px */

        letter-spacing: 0.0075em;

        /* KS_28353C */

        color: #28353c;
      }
      .siteCount {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 7px;

        width: 28px;
        height: 17px;

        /* 666666 */

        background: #666666;
        border-radius: 100px;
        margin-left: 8px;

        span {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 138%;
          /* identical to box height, or 17px */

          display: flex;
          align-items: center;
          letter-spacing: 0.0075em;

          /* KS_FFF */

          color: #ffffff;
        }
      }
    }
  }
  .siteDragItem {
    display: flex;
    flex-direction: row;
    position: "static" !important;
    background-color: #fff;
    box-shadow: 0px 1px 0px 0px #f2f2f2;
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 6.5px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1px;
    span {
      color: #28353c;
      /* KS_BodyRegular */
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%;
      letter-spacing: 0.105px;
    }
    .dragBox {
      padding-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      min-width: 34px;
    }
  }
}

.emptyDragDropContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    margin-top: 50px;
  }
  span {
    color: #28353c;
    text-align: center;
    /* KS_H5 */
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 24.84px */
    letter-spacing: 0.135px;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 50px;
  }
}

.mr20 {
  margin-right: 20px;
}
.hidden {
  animation: hide 0.75s forwards;
  -webkit-animation: hide 0.75s forwards;
}
.hide {
  display: none;
}

@keyframes hide {
  100% {
    display: none;
  }
}

@-webkit-keyframes hide {
  0% {
    display: none;
  }
  100% {
    display: none;
  }
}

.draggable {
  top: auto !important;
  left: auto !important;
}
