.headerWrapper {
  display: flex;
  flex-direction: column;
  min-height: 83px;
  max-height: 83px;
  width: 100%;
  border-bottom: 2px solid #ddd;
  z-index: 3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
.headerContainer {
  background: white;
  border: none;
  box-shadow: none;
  position: relative;
  min-height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding-top: 41px;
  padding-left: 31px;
  padding-right: 31px;
  padding-bottom: 10px;
}

.section1 {
  flex: 1;

  .arrowIcon {
  }
  .openBg {
    background-color: rgba(71, 74, 89, 0.1) !important;
  }

  .completedWrapper {
    display: flex;
    flex-direction: column;
    max-width: 76px;
    padding: 8px 8px 2px 8px;
    border-radius: 4px;
    background-color: #ffffff;

    .completedSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 6px;
      width: 100%;
      .tickIcon {
        width: 16.76px;
        height: 18px;
      }
      .countText {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 138%;
        /* identical to box height, or 22px */

        letter-spacing: 0.0075em;

        /* KS_28353C */

        color: #28353c;
      }
    }
    .completedText {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      /* identical to box height, or 14px */

      letter-spacing: 0.0075em;
      text-transform: uppercase;

      /* 666666 */

      color: #666666;
    }
  }
}

.headerLogo {
  flex: 1;
  height: 23px;
  width: 23px;
}

.DrawerContainer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #73818f;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 999;
}

.open {
  background: white;
  border: none;
  box-shadow: none;
  position: relative;
  animation: slideLeft 0.5s;
  animation-direction: alternate;
  right: 50%;
}
.menuIconWrapper {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
}
.menuIcon {
  cursor: pointer;
  width: 18px;
  height: 12px;
}

@keyframes slideLeft {
  0% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 50%;
    top: 0px;
  }
}
.progressContainer {
  position: relative;
  width: 100%;
  display: flex;
  .progressWrapper {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 2px;
    background-color: white;
    z-index: 20;
    .progress {
      background-image: linear-gradient(
        to right,
        #03ce91,
        #03ce91 75%,
        #ffc200 100%
      );
      height: 100%;
    }
    .progressFull {
      background-color: #03ce91;
      height: 100%;
    }
  }
  .headerPercentageBox {
    width: 31px;
    height: 13px;
    background: #ffffff;
    border-radius: 10px;
    bottom: -14px;
    left: 0px;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 138%;
      /* identical to box height, or 17px */

      text-align: center;
      letter-spacing: 0.0075em;
      text-transform: uppercase;

      /* KS_0F1619 */

      color: #0f1619;
    }
  }
}

@media (min-width: 768px) {
  .headerWrapper {
    width: 750px;
    align-self: center;
  }
  .headerContainer {
    width: 750px;
    align-self: center;
  }
}
