.container {
  width: 100%;
  padding: 20px;
  height: 100%;
}

.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
