.switch {
  background: #6fcf97;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  width: 101px;
  height: 35px;
  position: relative;
  justify-content: flex-start;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.active {
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 27px;
  height: 27px;
  border-radius: 27px;
  background: #4c4f5f;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  animation: slideRight 0.5s;
}
.disabled {
  position: absolute;
  left: 3px;
  bottom: 3px;
  width: 27px;
  height: 27px;
  border-radius: 27px;
  background: #4c4f5f;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  animation: slideLeft 0.5s;
}
.switchContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-weight: 600;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  animation: fadeIn 1s;
}

.label1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-weight: 600;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    left: 70%;
  }
  100% {
    left: 3px;
  }
}

@keyframes slideRight {
  0% {
    right: 70%;
  }
  100% {
    right: 3px;
  }
}
