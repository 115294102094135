@import "../../styles/variables";

.Title {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 24px;
  text-align: center;
  color: map-get($colors, c3);
}

.Content {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  color: map-get($colors, c3);
}
.Image {
  width: 90%;
  height: auto;
}
.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
