.animateUp {
  animation: toUp 4s;
  transition-delay: 5000ms;
}

.littleUp {
  margin-top: -60px;
}

@keyframes toUp {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 0;
  }
  100% {
    margin-top: -60px;
  }
}

.ruleIconRight {
  position: absolute;
  right: 0;
  background-color: #ccc;
  z-index: 100;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  height: 28px;
  width: 24px;
  top: 35px;
  padding: 2px 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.chapterCompletionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  .chapterAnimationWrapper {
    padding: 20px;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    position: relative;
    margin-top: 2vh;
    .chapterCompletionTickWrapper {
      position: absolute;
      top: 1vh;
      right: 1vh;

      img {
        width: 6vh;
      }
    }
  }
  .chapterCompletionIconBackground {
    border-radius: 50%;
    padding: 20px;
    background-color: #ffc200;
    height: 15vh;
    width: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 15vh;
      width: 15vh;
    }
  }

  .chapterCompletionTitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 138%;
    /* or 48px */

    text-align: center;
    letter-spacing: 0.0075em;
    text-transform: capitalize;

    /* KS_28353C */

    color: #28353c;
  }
  .chapterCompletionComplete {
    padding-top: 14px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 138%;
    /* or 48px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_28353C */

    color: #28353c;
  }
  .chapterCompletionProgressText {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 138%;
    /* identical to box height, or 36px */

    text-align: center;
    letter-spacing: 0.0075em;

    color: #000000;
    padding-top: 2vh;
  }

  .chapterIconProgressWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    padding-top: 2vh;
    .chapterIconsOuterWrapper {
      padding: 1vh;
      border-radius: 50%;
      border: 1px solid #e0e0e0;
    }
    .chapterIcons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10vw;
      height: 10vw;
      max-width: 80px;
      max-height: 80px;
      background-color: #ccc;
      border-radius: 50%;
      padding: 0.5vw;
      text-align: center;
      img {
        height: 90%;
        margin-top: 5%;
        max-height: 80px;
        filter: brightness(0) saturate(100%);
      }
      .blackChapterIcon {
        filter: brightness(0) saturate(100%);
      }
      .whiteChapterIcon {
        filter: brightness(0) saturate(100%) invert(100%) sepia(1%)
          saturate(7500%) hue-rotate(265deg) brightness(101%) contrast(102%) !important;
      }
    }
  }
}
