.notificationContainer {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.titleHead {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #303539;
  padding-left: 10px;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  position: fixed;
  width: 100%;
  padding-left: 256px;
  height: 40px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
}
.lightButton {
  width: 121px;
  height: 40px;
  background: #474a59;
  border: 1px solid #474a59;
  box-sizing: border-box;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #ffffff;
  margin-right: 20px;
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
}
.checkboxWrapper {
  margin: 20px auto 10px auto;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.darkButton {
  width: 200px;
  height: 40px;
  left: 1197px;
  background: linear-gradient(91.05deg, #03ce91 0.16%, #36bc94 100%);
  border: 1px solid #03ce91;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
  path {
    fill: #ffffff !important;
  }
  .icon {
    padding-left: 10px;
  }
}
.flex {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 80vh;
  width: 70vw;
}
.backIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headingWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 34px;
  border-bottom: 1px solid rgba(165, 180, 201, 0.5);
}

.h154 {
  height: 154px;
}
