.unchecked {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 2px solid #6fcf97;
}
.checked {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  &:hover {
    background: white;
    background-color: white;
    svg {
      fill: none !important;
    }
    path {
      fill: #6fcf97 !important;
    }
  }
}

.bigCheck {
  width: 16px !important;
  height: 16px !important;
}
