.customInput {
  display: flex !important;
  width: 100%;
  min-width: 210px;
  min-height: 35px;
  border-radius: 16px;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
  background: white;
  border: 1px solid #e0e0e0;
  align-items: center !important;
}
.datePickerWrapper {
  display: flex;
  flex-direction: column;
}
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.datePickerWrapper div.react-datepicker__day--in-range
{
  background: linear-gradient(91.05deg, #03ce91 0.16%, #36bc94 100%);
}