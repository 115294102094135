.Container {
  width: 100%;
  margin-bottom: 32px;
  position: relative;
}

.BlockLabel {
  display: block;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
}

.BlockInput {
  height: 44px;
  display: block;
  width: 100%;
  background: white;
  border-radius: 6px;
  font-family: inherit;
  font-size: 14px;
  color: #273d52;
  font-weight: bold;
  border: 1px solid #c8ced3;
  padding-left: 20px;

  &:focus {
    background: white;
    text-decoration: none;
    border: 1px solid #c8ced3;
    border-radius: 6px;
  }
  &::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #a5b4c9;
  }
}

.error {
  border: 2px solid #c40233 !important;
}
.errorMessage {
  margin-left: 5px;
  font-family: Open Sans;
  color: #c40233;
}

@media (min-width: 320px) and (max-width: 375px) {
  .Container {
    width: 100%;
    margin-bottom: 35px;
    position: relative;
  }

  .BlockLabel {
    font-size: 14px;
  }
}
@media (min-width: 375px) {
  .Container {
    width: 100%;
    margin-bottom: 35px;
    position: relative;
  }
}
