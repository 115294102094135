.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 80vh;
  width: 70vw;
}

.buttonContainer {
  position: fixed;
  width: 100%;
  padding-left: 256px;
  height: 66px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
}
.lightButton {
  width: 121px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #7185be;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #7185be;
  margin-right: 20px;
  &:hover {
    background: #7185be;
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
}

.darkButton {
  width: 121px;
  height: 40px;
  left: 1197px;
  background: #40507e;
  border: 1px solid #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: #40507e;
  }
  &:focus {
    outline: none;
  }
}

.flex {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #40507e;
  margin-bottom: 18px;
  margin-top: 34px;
}
.text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #40507e;
}
.addbutton {
  background: #ffffff;
  font-family: Open Sans;
  width: 176px;
  height: 34px;
  border: 1px dotted #7185be;
  border-radius: 17px;
  margin-top: 42px;
  margin-bottom: 42px;
  color: #40507e;
  outline: none;
  &:hover {
    text-decoration: none;
    background: #7185be;
    color: #ffffff;
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
.buttonText {
  position: relative;
  color: #40507e;
  font-family: Open Sans;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 0em;
  z-index: 33;
}
.button {
  background: #ffffff;
  font-family: Open Sans;
  position: relative;
  width: 176px;
  height: 34px;
  border: 1px solid #7185be;
  border-radius: 17px;
  margin-top: 32px;
  margin-bottom: 32px;
  color: #40507e;
  outline: none;

  &:hover {
    p {
      color: #ffffff;
    }
    text-decoration: none;
    background: #7185be;
    color: #ffffff;
    outline: none;
  }
  &:focus {
    // box-shadow: 0 0 3pt 2pt #7185be;
    text-decoration: none;
    background: #ffffff;
    color: #7185be;
    width: 176px;
    height: 34px;
    border-radius: 17px;
    outline: 1px solid #7185be;
    border: 1px solid #7185be;
    outline: none;
  }
  &:disabled {
    p {
      color: #a5b4c9;
    }
    text-decoration: none;
    outline: none;
    background: #ffffff;
    border: 1px solid #a5b4c9;
    box-sizing: border-box;
    border-radius: 17px;
  }
}

.divider {
  width: 90%;
  height: 1px;
  border: 1px solid #a5b4c9;
}

.container {
  display: flex;
  flex-direction: column;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 80vh;
  width: 70vw;
}

.uploadLanguageContainer {
  display: flex;
  flex-direction: row;
}
.inputContainer {
  display: flex;
  flex-direction: column;
}
.successLanguageContainer {
  width: 176px;
  height: 34px;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 18px;
  color: #40507e;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    display: flex;
    margin-left: 10px;
  }
}
.tickIcon {
  display: flex;
  width: 17px;
  height: 13px;
  margin-right: 10px;
}

.button > span {
  z-index: 2;
  display: block;
  height: 100%;
  border: 1px solid #7185be;
  border-radius: 17px;
  background-color: #7185be;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}
.button > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  animation: move 2s linear infinite;
  border: 1px solid #7185be;
  border-radius: 17px;
  background-color: #7185be;
  overflow: hidden;
}
.error {
  color: #e26c82;
  font-size: 12px;
  font-family: "Open Sans";
  position: absolute;
  bottom: 5px;
  left: 10px;
}
.configContainer {
  cursor: pointer;
  position: relative;
  display: flex;
  border-radius: 34px;
  width: 34px;
  height: 34px;
  background-color: #a5b4c9;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-left: 26px;
  &:hover {
    background-color: #7185be;
    path {
      fill: #ffffff;
    }
  }
}
path:hover {
  fill: #ffffff;
}

.configImage {
  height: 50%;
}
.configContent {
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 34px;
  width: 126px;
  height: 34px;
  border-radius: 6px;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.configContent span {
  display: flex;
  font-family: "Open Sans";
  font-size: 14px;
  color: #40507e;
  text-decoration: none;
  outline: none;
  align-self: center;
  justify-content: center;
  margin-top: 5px;
  cursor: pointer;
}

.configContainer:hover .configContent {
  display: block;
}
.deleteButton {
  width: 214px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e26c82;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #e26c82;

  &:hover {
    background: #e26c82;
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
}

.paginationContainer {
  background: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
  height: 35px;
  width: calc(100% - 256px);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  span {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(165, 180, 201, 0.2);
    }
  }
}
