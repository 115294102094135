.tableHeaderContainer {
  position: relative;
  display: flex;
  overflow-x: auto;
}

.tableHead {
  position: relative !important;
  display: flex;
  overflow-x: auto;
}
.tableWrapper {
  width: 80%;
  margin: 27px auto;
  table {
    width: 100% !important;
  }
}

.headContainer {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.titleHead {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 138%;
  /* or 33px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* FRL Deep Ocean Grey */

  color: #303539;
}

.statusWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-right: 50%;
  margin-bottom: 6px;
}
.status {
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: #bb6bd9;
}
.statusGray {
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: #dddddd;
}

.buttonContainer {
  position: fixed;
  width: 100%;
  padding-left: 256px;
  height: 66px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
}

.darkButton {
  width: 121px;
  height: 40px;
  left: 1197px;
  background: #40507e;
  border: 1px solid #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    background: gray;
    color: #40507e;
  }
  &:hover {
    background: #ffffff;
    color: #40507e;
  }
  &:focus {
    outline: none;
  }
}

.filterContainer {
  display: flex;
  flex-direction: row;
  padding-top: 17px;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
}

.filterWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.indicatorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .indicatorItem {
    margin-left: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 8px;
    }
    span {
      display: flex;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.0075em;
      color: #40507e;
    }
  }
}

.paginationContainer {
  height: 35px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 200px;
  span {
    background: #ffffff;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    &:hover {
      background: rgba(165, 180, 201, 0.2);
    }
  }
}

.thumpsIcon {
  width: 22px;
  height: 20px;
}

.backIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 10px 10px 10px 0px;
}

.indicatorTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #000000;
  margin-right: 32px;
}
.indicatorType1 {
  width: 27px;
  height: 27px;
  background: #6fcf97;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: #ffffff;
  }
}

.indicatorType2 {
  width: 27px;
  height: 27px;
  background: #e26c82;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: #ffffff;
  }
}

.buttonWrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.attendanceDetailTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138%;
  /* identical to box height, or 28px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.checkinStatus {
  color: #03ce91;
}
