@import "../../../styles/variables.scss";
.Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  [data-id="start"][contentEditable="false"]:empty:not(:focus):before {
    content: attr(data-text);
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;
    color: #44c032;
  }
}

.Category {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* 666666 */

  color: #666666;
  text-align: center;
  padding: 0 15px;
  margin-bottom: 13px;
  margin-top: 32px;
}
.Title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */

  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #0f1619;
  text-align: start;
  margin-bottom: 13px;
}
.Content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
  text-align: start;
  margin-bottom: 35px;
}

.Question {
  @media (max-width: 321px) {
    width: 140px;
  }
  width: 165px;
  height: 95px;
  border: 1px solid #e9ebec;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: #28353c;
}
.QuestionDescription {
  width: 100%;
  height: auto;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.QuestionFeedback {
  width: 100%;
  height: auto;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: map-get($colors, c19);
  padding: 10px;
}
.Keyword {
  align-self: flex-start;
  width: 100%;
  height: 48px;
  padding: 0 9px;
  background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
  border-radius: 6px 6px 30px 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #ffffff;
  text-align: center;
}
.DotsContainerPreview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.dotsPreview {
  height: 4px;
  width: 10px;
  color: #323232 !important;
  span {
    color: #323232 !important;
  }
}
.DotsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.dots {
  height: 4px;
  width: 10px;
  color: #ffffff !important;
  span {
    color: #ffffff !important;
  }
}

.SuccessBackground {
  background-color: rgba(68, 192, 50, 0.1) !important;
}
.ErrorBackground {
  background-color: rgba(226, 108, 130, 0.1) !important;
}

.Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 321px) {
    min-width: 140px;
    max-width: 140px;
  }
  min-width: 165px;
  max-width: 165px;
  width: 45%;
  left: 104px;
  top: 359px;
  align-self: center;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 13px;
  margin: 5px;
}

.WellDoneWrapper {
  width: 167px;
  height: 95px;
  min-height: 95px;
  margin: 0 auto;
  background: transparent;
  border: 4px dashed #dddddd;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;
    color: #28353c;
  }
}
.columnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.descriptionWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .successIcon {
    padding-top: 11px;
    animation: fadeIn 1s;
  }
  .counter {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 138%;
    /* or 17px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;

    /* 999999 */

    color: #999999;
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
.categoryTitle {
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */
  align-self: center;
  justify-self: center;
  color: #0f1619;
  margin: 5px auto;
  // margin-bottom: 5px;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 168px;
  @media (max-width: 321px) {
    min-width: 140px;
  }
  min-height: 110px;
  margin: 0 auto;
  span {
    text-align: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #0f1619;
    // margin-bottom: 5px;
  }
}

.AnswerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5px;
  // [contentEditable="false"]:empty:not(:focus):before {
  //   content: attr(data-text);
  //   opacity: 0.5;
  // }
  .Card {
    border: 4px dashed #e9ebec;
    width: 45%;
    height: 93px;
    margin-bottom: 50px;
    .Question {
      height: 93px;
      max-width: 100%;

      .QuestionDescription {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: calc(20vh - 5rem);
        color: #ffffff;
        display: flex;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        &:hover {
          overflow: auto;
          white-space: pre-wrap;
        }
      }
      .QuestionFeedback {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          overflow: auto;
          white-space: pre-wrap;
        }
      }
    }
  }
  margin-bottom: 8rem;
}
.EndCardWrapper {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
  justify-content: center;
  width: 100%;
  .EndCard {
    flex: 1;
    min-height: 167px;
    max-width: 167px;
    margin: 5px;
    background: #e9ebec;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-self: center;
    position: relative;
  }
  .Results {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    img {
      margin-top: 53px;
      width: 40px;
      height: 40px;
    }
    span {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 138%;
      /* or 19px */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.0075em;
      align-self: center;
      /* KS_28353C */

      color: #28353c;
      margin-top: 28px;
    }
  }
}

.replayButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 11px 7px 13px;
  border: 1px solid #44c032;
  border-radius: 30px;
  margin: 30px auto;
  width: 92px;
  height: 33px;
  background-color: white;
  outline: none;
  span {
    display: flex;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.0075em;
    color: #44c032;
  }
  img {
    display: flex;
    align-self: center;
    justify-self: center;
    margin-left: 10px;
    width: 10px;
    height: 10px;
  }
}

.WellDone {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.0075em;
  color: #28353c;
  margin-bottom: 14px;
}

.Preview {
  display: flex;
  max-width: 750px;
}
.hidden {
  opacity: 0 !important;
}
@media (max-width: 768px) {
  .Preview {
    width: 100%;
    max-width: 100%;
  }
  .Card {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .Preview {
    width: 100%;
    max-width: 100%;
  }
  .Card {
    width: 337px;
    .Question {
      max-width: 100%;
    }
  }
}
@media (min-width: 768px) {
  .start {
    .Question {
      width: 100%;
      max-width: 100%;
    }
  }
}

@keyframes toUp {
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 0px;
  }
  100% {
    margin-top: -40px;
  }
}

.portal {
  position: absolute;
  top: 250px;
  left: 0;
  width: 100%;
  padding: 30px;
  margin-top: -40px;
  @media (min-width: 768px) {
    position: absolute;
    top: 250px;
    left: calc(calc(100vw - 750px) / 2);
    width: 750px;
    padding: 10px;
    padding: 0 0.9375rem;
  }
}
.fadeIn {
  animation: fadeIn 2s;
}

.fadeOut {
  animation: fadeOut 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
