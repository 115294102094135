@import "../../../styles/variables";

.Button {
  width: 100%;
  height: 60px;
  background: linear-gradient(180deg, #44c032 0%, #3da92e 100%);
  border: none;
  outline: none;
  text-decoration: none !important;
  &:hover {
    background: #44c032;
    outline: none;
    text-decoration: normal !important;
    list-style: none;
  }
  &:focus {
    background: #44c032;
    border: none;
    outline: none;
    text-decoration: normal !important;
    list-style: none;
  }
}

.YellowButton {
  width: 100%;
  height: 60px;
  background: #f4bb44;
  border: 1px solid #f4bb44;
  outline: none;
  color: #28353c !important;
  span {
    color: #28353c !important;
    font-weight: 600 !important;
  }
  text-decoration: none !important;
  &:hover {
    background: #f4bb44;
    border: 1px solid #f4bb44;
    outline: none;
    text-decoration: normal !important;
    list-style: none;
    opacity: 0.9;
  }
  &:focus {
    background: #f4bb44;
    border: 1px solid #f4bb44;
    outline: none;
    text-decoration: normal !important;
    list-style: none;
    opacity: 0.8;
  }
}

.LightButton {
  width: 100%;
  height: 60px;
  background: transparent;
  border: 1px solid #44c032;
  outline: none;
  color: #44c032;
  text-decoration: none !important;
  &:hover {
    background: transparent;
    border: 1px solid #44c032;
    outline: none;
    text-decoration: normal !important;
    list-style: none;
  }
  &:focus {
    background: transparent;
    border: 1px solid #44c032;
    outline: none;
    text-decoration: normal !important;
    list-style: none;
  }
}

.BlueButton {
  width: 100%;
  height: 60px;
  background: #28353c;
  border: none;
  text-decoration: none !important;
  &:hover {
    text-decoration: none;
    background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
    border: none;
    outline: none;
    text-decoration: none !important;
    list-style: none;
  }
  &:focus {
    text-decoration: none;
    background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
    border: none;
    border: none;
    outline: none;
    text-decoration: none !important;
    list-style: none;
  }
}

.TextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ButtonIcon {
  padding-left: 10px;
}
.Text {
  font-family: Open Sans;
  color: white;
  font-size: 16px;
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }

  &:focus {
    text-decoration: none !important;
  }
}

.backButton {
  width: 100%;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none !important;
}
.SubText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 17px */

  letter-spacing: 0.0075em;

  /* FFF */

  color: #ffffff;
  text-decoration: none !important;
  list-style: none;
}
