.existingHazardsContainer {
  height: auto;
  position: absolute;
  right: 0px;
  top: 81px;
  width: 176px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px 0px 0px 6px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.iconWrapper {
  width: 59px;
  height: 59px;
  min-height: 59px;
  border: 2px solid #6fcf97;
  border-radius: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.existHazardBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 150px;
  height: 126px;
  background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin-bottom: 12px;
  cursor: pointer;
  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 138%;
    text-align: center;
    letter-spacing: 0.0075em;
    text-transform: uppercase;
    color: #ffffff;
  }
  &:hover {
    opacity: 0.9;
  }
}
.existHazardTitleWrapper {
  background: #f8f8f8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px 0px 0px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 8px;

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: #000000;
  }
}

.slideInEx {
  animation: slide-in 0.75s forwards;
}

.slideOutEx {
  animation: slide-out-ex 0.75s forwards;
  -webkit-animation: slide-out-ex 0.75s forwards;
}
.hidden {
  display: none !important;
  overflow: hidden;
}

@keyframes slide-in {
  0% {
    right: -176px;
  }
  100% {
    right: 0px;
  }
}

@keyframes slide-out-ex {
  0% {
    right: 0px;
  }
  100% {
    right: -176px;
  }
}
