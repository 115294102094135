.CounterContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.tableWrapper {
  width: 80%;
  margin: 0 auto;
  table {
    width: 100% !important;
  }
}
.paginationContainer {
  background: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
  height: 35px;
  width: calc(100% - 256px);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  span {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(165, 180, 201, 0.2);
    }
  }
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 82%;
  margin: 0 auto;
}
