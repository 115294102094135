.h250 {
  height: 250px !important;
}
.uploading {
  background: #bb6bd9 !important;
  animation: fadeIn 1s;
}

.previewImageWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.previewImageShadow {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(40, 53, 60, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.7;
}

.changePictureButton {
  z-index: 2;
  border-radius: 100px;
  border: 2px solid var(--bb-6-bd-9, #bb6bd9);
  background: var(--ksfff, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  width: 126px;
  height: 126px;
  position: absolute;
  left: calc(50% - 58px);
  top: calc(50% - 58px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 2px solid var(--ksfff, #fff);
    background: var(--bb-6-bd-9, #bb6bd9);
    opacity: 1;
    path {
      fill: #ffffff !important;
    }
    span {
      color: #ffffff !important;
    }
  }
  &:focus {
    border: 2px solid var(--ksfff, #fff);
    background: var(--bb-6-bd-9, #bb6bd9);
    opacity: 1;
    path {
      fill: #ffffff !important;
    }
    span {
      color: #ffffff !important;
    }
  }
  span {
    margin-bottom: 0px !important;
  }
}

.uploadingWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
}
.progresWrapper {
  height: 2px;
  border-radius: 0px 10px 10px 0px;
  background: #fff;
  width: 90%;
  position: relative;
  margin-bottom: 5px;
  overflow: hidden;
  .progressBar {
    position: absolute;
    left: 0;
    top: 0;
    height: 2px;
    border-radius: 0px 10px 10px 0px;
    background: var(--ks-0-f-1619, #0f1619);
  }
}
.uploadText {
  color: var(--ksfff, #fff) !important;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* Body regular OpenSans */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 138%; /* 19.32px */
  letter-spacing: 0.105px;
}

.progressPercentageWrapper {
  border-radius: 10px;
  background: var(--ksfff, #fff);
  width: 33px;
  height: 19px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  .progressPercentageText {
    color: var(--9-b-51-e-0, #9b51e0);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px !important;
  }
}

.removed {
  animation: fadeIn 2s;

  span {
    color: #f4bb44 !important;
  }

  path {
    fill: #f4bb44 !important;
  }
}

.error {
  animation: fadeIn 2s;
  background: #e26c82 !important;
  &:hover {
    background: #e26c82 !important;
  }
  &:focus {
    background: #e26c82 !important;
    span {
      color: #ffffff !important;
    }
    path {
      fill: #ffffff !important;
    }
  }
  span {
    color: #ffffff !important;
    margin-right: 10px;
    margin-left: 10px;
  }
  path {
    fill: #ffffff !important;
  }
}

.success {
  animation: fadeIn 2s;
  background: #03ce91 !important;
  &:hover {
    background: #03ce91 !important;
  }
  &:focus {
    background: #03ce91 !important;
    span {
      color: #ffffff !important;
    }
    path {
      fill: #ffffff !important;
    }
  }
  span {
    color: #ffffff !important;
  }
  path {
    fill: #ffffff !important;
  }
}

.uploadImageWrapper {
  margin: 20px 0px 40px;
  width: 100%;
  background: #ffffff;
  border: 1px dashed #999999;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  &:hover {
    background: #def3f0;
  }

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* 9B51E0 */

    color: #9b51e0;
    margin-bottom: 40px;
  }
  .previewIcon {
    margin: 20px;
  }

  .previewImage {
    width: 100% !important;
    height: 100% !important;
    border-radius: 6px;
    margin: 0px;
    object-fit: cover;
  }
}

.uploadInput {
  display: none;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.actionBox {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;

  .popOver {
    width: 140px;
    border-radius: 6px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 10px;
    bottom: -90px;
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;
    flex-direction: column;
    .popOverItem {
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      box-shadow: 0px 1px 0px 0px #f2f2f2;
      background-color: white;
      width: 100%;
      height: 41px;
      margin-bottom: 1px;
      svg {
        margin-right: 20px;
      }
      &:first-child {
        margin-bottom: 0px !important;
        border-radius: 6px 6px 0px 0px;
      }
      &:last-child {
        margin-bottom: 0px !important;
        border-radius: 0px 0px 6px 6px;
      }
      &:hover {
        background-color: #f8f8f8;
      }
    }

    span {
      color: #28353c;
      /* Body regular OpenSans */
      font-family: Open Sans;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 138%; /* 19.32px */
      letter-spacing: 0.105px;
      margin-bottom: 0px !important;
    }
  }
  .actionButton {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    opacity: 0.7;
    z-index: 2;
    background-color: #ffffff;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}
