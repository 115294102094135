.heading {
  display: flex;
  padding: 12px 0px 12px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  .headingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .headingIconWrapper {
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
    }
    .red {
      background: #e26c82;
    }
    .orange {
      background: #f4bb44;
    }
  }

  .title {
    color: #28353c;
    font-size: 14px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
    letter-spacing: 0.105px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .count {
    display: flex;
    padding: 0px 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #28353c;
    gap: 10px;
    color: #fff;
    /* Overline Roboto */
    font-size: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
    letter-spacing: 0.09px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
}

.descriptionWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: #fff;
  box-shadow: 0px 1px 0px 0px #f2f2f2;
  span {
    padding: 20px 20px 20px 20px;
    color: #28353c;
    /* KS_BodyMedium */
    font-size: 14px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
    letter-spacing: 0.105px;
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.withImage {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0px 0px 4px 4px;
  background: #f2f2f2;
  border: 2px solid #ffffff;
}
.cardBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;

  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 15px;
  .cardDescContainer {
    display: flex;
    flex-direction: row;
    margin-top: 13px;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }
    .mainBox {
      width: 100%;
      display: flex;
      flex: 1;
      display: flex;
      flex-direction: column;
      .descWrapper {
        display: flex;
        padding-left: 10px;
        padding-bottom: 10px;
        flex-direction: column;
      }
      .cardTitleWithImage {
        font-size: 14px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        line-height: 138%;
        letter-spacing: 0.105px;
      }
      .cardMessageWithImage {
        color: #ffc200;
        font-size: 14px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        line-height: 138%;
        letter-spacing: 0.105px;
        color: #28353c;
        display: flex;
      }

      .cardTitle {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 138%;
        /* identical to box height, or 22px */

        display: flex;
        align-items: center;
        letter-spacing: 0.0075em;

        /* E26C82 */

        color: #e26c82;
        display: flex;
        margin-bottom: 10px;
      }
      .cardIconWrapper {
        width: 44px;
        height: 44px;
        min-height: 44px;
        border: 1px solid #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-left: 10px;
      }
      .cardMessage {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 138%;
        /* or 19px */

        display: flex;
        align-items: center;
        letter-spacing: 0.0075em;

        /* KS_28353C */

        color: #28353c;
      }
      .mainImage {
        border-radius: 4px 4px 0px 0px;
        width: 100%;
        height: 120px;
        object-fit: cover;
      }
    }
  }
}
.siteHazardContainerOpen {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: #ffffff;
  width: 100%;
  animation: toUp 1.5s;
}

.none {
  display: none;
}

.siteHazardCollapsed {
  position: absolute;
  top: calc(100vh - 150px);
  left: 0;
  background-color: #ffffff;
  // width: 100%;
  max-height: 70px;
  overflow: hidden;
  margin-left: 2.5%;
  margin-right: 2.5%;
  border-radius: 6px 6px 0px 0px;
  background: var(--ksfff, #fff);
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);
  width: 95%;
}
.animate {
  animation: toDown 1s;
}
@keyframes toDown {
  0% {
    top: 0;
    min-height: 100vh;
  }
  90% {
    min-height: 50vh;
  }
  100% {
    top: calc(100vh - 150px);
    max-height: 70px;
  }
}

.rotate180 {
  transform: rotate(-90deg);
}
.rotate90 {
  transform: rotate(90deg);
}

@keyframes toUp {
  0% {
    top: calc(100vh - 150px);
    margin-left: 15px;
    margin-right: 15px;
  }
  10% {
    top: calc(100vh - 150px);
    margin-left: 0px;
    margin-right: 0px;
  }
  100% {
    top: 0;
  }
}
