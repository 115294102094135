.buttonContainer {
  background: #ffffff;
  font-family: Open Sans;
  position: relative;
  width: 136px;
  height: 34px;
  border: 1px solid #7185be;
  border-radius: 17px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;

  color: #40507e;
  outline: none;

  &:hover {
    span {
      color: #ffffff;
    }
    text-decoration: none;
    background: #7185be;
    color: #ffffff;
    outline: none;
    svg {
      fill: #fff;
    }
    path {
      fill: #fff;
    }
  }

  &:disabled {
    span {
      color: #a5b4c9;
    }
    text-decoration: none;
    outline: none;
    background: #ffffff;
    border: 1px solid #a5b4c9;
    box-sizing: border-box;
    border-radius: 17px;
  }
  &:focus {
    outline: none;
  }
}

.deleteContainer {
  background: #ffffff;
  font-family: Open Sans;
  position: relative;
  width: 136px;
  height: 34px;
  border: 1px solid #e26c82;
  border-radius: 17px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  animation: easein 0.5s ease-in;
  animation-timing-function: linear;
  color: #40507e;
  outline: none;

  &:hover {
    span {
      color: #ffffff;
    }
    text-decoration: none;
    background: #e26c82;
    color: #ffffff;
    outline: none;
    svg {
      fill: #fff;
    }
    path {
      fill: #fff;
    }
  }
  &:focus {
    outline: none;
  }
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  div {
    display: flex;
    margin-right: 20px;
    align-self: center;
  }
  span {
    display: flex;
  }
}

@keyframes easein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@media print {

   .buttonContainer{display: none;}
   .deleteContainer{display: none;}

}

