.paginationContainer {
  height: 35px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 200px;
  span {
    background: #ffffff;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    &:hover {
      background: rgba(165, 180, 201, 0.2);
    }
  }
}
