.tags {
  background: #f8f8f8;
  padding: 5px;
  margin: 5px;
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  span {
    font-size: 12px !important;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #40507e;
  }
  &:hover {
    background: #f8f8f8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}
.container {
  background-color: white;
  div:nth-child(2) {
    background: #7185be;
    border-radius: 6px;
    color: white;
    div:nth-child(1) {
      background: transparent;
    }
    div:nth-child(2) {
      background: transparent;
      border-color: #7185be !important;
      svg {
        fill: #7185be;
        path {
          fill: #7185be;
        }
      }
      &:after {
        // box-shadow: 0 0 3pt 2pt #7185be;
        content: "";
        border-bottom-color: #7185be !important;
        fill: #7185be;
        color: #7185be;
      }
      &:before {
        content: "";
        // box-shadow: 0 0 3pt 2pt #7185be;
        border-bottom-color: #7185be !important ;
        fill: #7185be;
        color: #7185be;
      }
    }
    div:nth-child(3) {
      background: transparent;
      background: #7185be;
    }
  }
}
