.Container {
  height: calc(100vh - 140px);
  overflow: hidden;

  width: 100%;
  margin: auto;
  top: -140px;
  left: 0;
  position: relative;
  display: inline-block;
  // Header Component should have z-index:1 and position relative.
  margin: auto;
  scroll-behavior: none;
}

.TextContent {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  background: #ecf9eb;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 75px 0px 0px 0px;
  width: 100%;
  min-height: 130px;
  padding-top: 36px;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 80px;
  position: absolute;
  bottom: 0;
  .title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 138%;
    /* identical to box height, or 25px */

    letter-spacing: 0.0075em;

    /* KS_28353C */

    color: #28353c;
    text-align: start;
  }
  .description {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    text-align: start;
    color: #28353c;
  }
}
