.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 55px;
  /* or 196% */

  letter-spacing: 2.78155e-9px;

  color: #000000;
  margin: 18px;
}
.Form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.ItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  align-self: center;
  padding-bottom: 200px;
  @media (min-width: 1024px) {
    width: 750px;
  }
}
.icon {
  width: 139px;
  height: 104px;
  margin: 100px auto;
  display: flex;
}

.ItemContainerSubmitted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 200px;
  transition: 300ms;
  opacity: 0;
  transform: translateY(100px);
}

.successWrapper {
  opacity: 1;
  //transition: 500ms;
  //transition-delay: 300ms;
  //transform: scale(0.1);
  position: absolute;
  width: 100%;
  max-width: 750px;
  top: 30%;
}

.successWrapperVisible {
  transition: 500ms;
  transition-delay: 200ms;
  opacity: 1;
  position: absolute;
  transform: scale(1);
  max-width: 750px;
  width: 100%;
  top: 30%;
}

.footerDefault {
  transition: 500ms;
  position: fixed;
}

.footerSlideIn {
  transition: 500ms;
}
