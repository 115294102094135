.ItemCard {
  width: 90vw;
  height: 133px;
  background: #ffffff;
  /* F8F8F8 */
  margin-top: 72px;
  padding-top: 50px;
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  position: relative;
}
.ItemText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 138%;
  /* or 25px */

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #0f1619;
}
.ItemIcon
{
  position: absolute;
  bottom: 0;
  right: 4px;
}
.ItemIcon img
{
  width: 32px;
}
.ItemDocumentUploadedText
{
  position: absolute;
  bottom:6px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #44c032;
  font-size: 16px;
}
.Checkbox {
  position: absolute;
  top: -26px;
  left: calc(50% - 26px);
  width: 52px;
  height: 52px;
  background: #f2f2f2;
  padding: 10px;
  /* FFF */
  border-radius: 52px;
  border: 5px solid #ffffff;
  box-sizing: border-box;
}
.ItemCheckboxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.CorrectIconImage {
  width: 100%;
  height: 100%;
}

@media (max-width: 760px) {
  .ItemCard {
    width: 100%;
    height: 133px;
    background: #ffffff;
    /* F8F8F8 */
    padding-top: 0px;

    border: 1px solid #f8f8f8;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ItemText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 138%;
    /* or 25px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_0F1619 */

    color: #0f1619;
  }
}
