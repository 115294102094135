.table {
  width: 65vw;
  min-width: 600px;
  border: 1px solid #f6f7fa;
  height: auto;
  font-family: Open Sans;
  background-color: white;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 50vh;
  width: 100%;
}
.thead {
  height: 40px !important;
  background: rgba(111, 207, 151, 0.1);
  border-radius: 6px 6px 0px 0px;
}
.row {
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: #28353c;
  padding: 2px;
}
.columns {
  border-bottom: 1px solid #f6f7fa;
  height: 60px;
}
.menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Open Sans;
  width: 34px;
  height: 34px;
  border-radius: 6px;

  &:hover {
    background: #ffffff;
  }
}
.headElement {
  font-family: Open Sans;
  font-style: normal;
  font-size: 11px;
  line-height: 138%;
  color: #28353c;
  font-weight: 600;
  text-transform: uppercase;
}

.asc {
  margin-left: 2px;
}

.dsc {
  margin-left: 2px;
  transform: rotate(180deg);
}
.searchBox {
  background-color: white;
  height: 63px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.searchIcon {
  display: flex;
  width: 18px;
  height: 18px;
  margin-left: 26px;
}
.searchInput {
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 25px;
  font-style: normal;
  font-weight: normal;
  background-color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.0075em;
  color: #40507e;
  font-family: Open Sans;
  &:focus {
    outline: none;
    border: none;
  }
  &::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.0075em;
    font-family: Open Sans;

    color: #a5b4c9;
    margin-top: 10px;
  }
}

.container {
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  margin: 5px 5px 100px 5px;
}

.clickable {
  cursor: pointer;
  border-bottom: 1px solid #f6f7fa;
  height: 60px;
  &:hover {
    background: #f8f8f8;
  }
  &:active {
    background: #f2f2f2;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltipMessage {
  width: 100px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  background-color: #fafafa;
  color: #1d1d1d;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  font-family: Open Sans;
  z-index: 1;
  top: 25px;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fafafa transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.popOver {
  min-width: 143px;
  max-width: 160px;
  border-radius: 6px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 10px;
  bottom: -130px;
  display: flex;
  align-items: center;
  background-color: white;

  cursor: pointer;
  flex-direction: column;
  .popOverItem {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    box-shadow: 0px 1px 0px 0px #f2f2f2;
    background-color: white;
    width: 100%;
    height: 41px;
    margin-bottom: 1px;
    svg {
      margin-right: 20px;
    }
    &:first-child {
      margin-bottom: 0px !important;
      border-radius: 6px 6px 0px 0px;
    }
    &:last-child {
      margin-bottom: 0px !important;
      border-radius: 0px 0px 6px 6px;
    }
    &:hover {
      background-color: #f8f8f8;
    }
  }

  span {
    color: #28353c;
    /* Body regular OpenSans */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 19.32px */
    letter-spacing: 0.105px;
  }
}

// .configContent {
//   display: block;
//   position: absolute;
//   justify-content: center;
//   align-items: center;

//   width: 103px;
//   height: 102px;
//   border-radius: 6px;
//   background-color: #fff;
//   min-width: 103px;
//   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//   z-index: 9999;
// }

// .configContent span {
//   display: flex;
//   font-family: "Open Sans";
//   font-size: 12px;
//   color: #40507e;
//   text-decoration: none;
//   border-bottom: 1px solid #f2f2f2;
//   height: 34px;
//   padding-top: 6px;
//   padding-bottom: 5px;
//   outline: none;
//   align-self: center;
//   justify-content: center;
//   cursor: pointer;
// }
