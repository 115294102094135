.dashboardSection2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 20px 30px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.language {
  height: 60px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    width: 60px;
    /* or 19px */

    display: flex;
    align-items: center;
    letter-spacing: 0.0075em;

    /* 40507E */

    color: #40507e;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-bottom: 0em !important;
    display: flex;
  }
}

.progressWrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 2px;
  background: #e9ebec;
  border-radius: 0px 0px 10px 10px;
  margin-left: 36px;
}

.progress {
  background: #d54250;
  opacity: 0.5;
  border-radius: 0px 0px 10px 10px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}
.progressCount {
  position: absolute;
  right: 0px;
  top: -20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 17px */

  letter-spacing: 0.0075em;
  color: #d54250 !important;
}

@media (max-width: 1250px) {
  .dashboardSection2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 2;
    padding: 20px 30px;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
}
