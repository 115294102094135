.PermissionModal {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2001;
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
}
.box {
  display: flex;
  width: 463px;
  min-height: 482px;
  height: 100%;
  padding-bottom: 50px;
  height: auto;
  background: #ffffff;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 40%;
  margin: 20px auto;
}
.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 138%;
  padding-left: 39px;
  padding-right: 39px;
  margin-bottom: 28px;
  letter-spacing: 0.0075em;
  color: #28353c;
}

.description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 138%;
  padding-left: 39px;
  padding-right: 39px;
  /* or 22px */

  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
}
