@import "../../../../styles/variables.scss";

.ModalWrapper {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  padding: 8.75rem 1rem;
  padding-top: 0;
  z-index: 1500;
  /*top: 80px;*/
}
.ModalWrapperHotspot {
  min-height: 80vh;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  padding: 0;
  z-index: 1500;
}

.ModalWrapperIntro
{
  min-height: 80vh;
  width: 100%;
  top: -15vh;
  left: 0;
  position: fixed;
  padding: 0;
  z-index: 1500;
}

.Modal {
  padding: 2.5rem;
  background: map-get($colors, c5);
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  max-height: 529px;
  position: relative;
  scroll-behavior: none;
  p
  {
    max-width: 100%;
  }
}

.ModalWrapperIntro .Modal
{
  border-radius: 0 !important;
}


.ModalHotspot {
  padding: 2.5rem;
  background: map-get($colors, c5);
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  min-height: 80vh;
  overflow: auto;
}

.TextContent {
  position: absolute;
  top: 117px;
  margin: 0 auto;
  margin-bottom: 2.875rem;
  padding: 0 2rem;
}
.TitleWrapper {
  color: map-get($colors, c6);
  font-size: 1.375rem;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  text-align: center;
  letter-spacing: 0.0075em;
  line-height: 1.875rem;
  margin-bottom: 13px;

}

.ModalWrapperIntro .TitleWrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 138%;
  text-align: center;
  color: #28353c;
}

.ContentWrapper {
  color: map-get($colors, c6);
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: map-get($colors, c1);
  align-items: center;
  word-break: break-word;
  margin-bottom: 1rem;
}

.ButtonWrapper {
  width: 100%;
  margin-top: 62px;
  display: flex;
  justify-content: center;
  button {
    width: 150px;
    height: 44px;
    align-self: center;
  }
}

.closeIconWrapper {
  width: 44px !important;
  height: 44px !important;
  background-color: #ffffff !important;
  border: 5px solid #ecf9eb;
  border-radius: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -16px;
  top: -18px !important;
  &:hover {
    opacity: 0.9;
  }
  cursor: pointer;
}

.ModalHotspot .closeIconWrapper {
  right: 15px;
}

.ModalWrapper .ModalClose {
  top: 0;
}

.ModalVisible {
  transform: translateY(15vh);
  transition: 500ms;
  min-height: 60vh;
  position: fixed;
  width: 100%;
  z-index: 1500;
}
.ModalHidden {
  transform: translateY(100vh);
  transition: 500ms;
  min-height: 80vh;
  position: fixed;
  width: 100%;
  z-index: 1500;
}

@media (min-width: 1024px) {
  .ModalWrapper {
    width: 750px;
    left: inherit;
  }
}

@media only screen and (max-height: 480px) and (orientation: landscape) {
  .ModalVisible {
    transform: translateY(-74px);
    transition: 500ms;
    min-height: 80vh;
    position: fixed;
    width: 80%;
    left: 10%;
    z-index: 1500;
  }
}
