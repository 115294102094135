.counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 30px;
  width: 10%;
}

.count {
  display: flex;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #40507e;
}
.title {
  display: flex;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #7185be;
}
