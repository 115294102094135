@import "../../../styles/_variables.scss";

.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  margin-top: 20px;
}
.CarouselContainer {
  display: flex;
  justify-items: center;
  width: 100%;
}
.Carousel {
  box-sizing: border-box;
  background: map-get($colors, c12);
  border: 1px solid map-get($colors, c23);
  box-shadow: 1px 1px 1px map-get($colors, boxShadow);
  border-radius: 0.375rem;
  min-height: 23.5625rem;
  flex-direction: column;
  margin: auto;
  width: 92%;
  display: flex;
  margin: 1rem auto;
  @media (min-width: 1024px) {
    min-height: 50vh;
  }
}

.dotswrapper {
  background-color: #ccc;
}
.nextbutton::before {
  display: none;
}
.sliderwrapper {
  width: 100%;
  .slick-list {
    overflow: visible;
    div > div > div > .arrow {
    }
  }
  .slick-slider slick-initialized {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    .slick-arrow {
      display: block !important;
    }
  }
}
.sliderwrapper li {
  margin: 0 !important;
}
.Starter {
  display: flex;
}
.Current {
  display: flex;
  animation-name: moving;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}
.Inactive {
  animation-name: fade-out;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}
.Button {
  margin-top: auto;
  margin-bottom: 3.875rem;
  display: flex;
  justify-content: center;
  button {
    width: 14.4375rem;
    height: 2.75rem;
    border-radius: 1.375rem;
  }
}
.Header {
  background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
  border-radius: 0.375rem 0.375rem 2.8125rem 0px;
  font-size: 1.125rem;
  line-height: 138%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;
  margin-bottom: 2.125rem;
  color: map-get($colors, c5);
  min-height: 4.125rem;
  @media (min-width: 1024px) {
    margin-bottom: 0px;
  }
}
.aspectRatioWrapper {
  padding: 0px 18px 0px 18px;
  // background: map-get($colors, c6);
  .aspectRatioBox {
    height: 0;
    overflow: hidden;
    padding-top: 591.44px / 1127.34px * 100%;
    // background: map-get($colors, c6);
    position: relative;
    border-radius: 0.375rem;
    .Image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.375rem;
      align-self: center;
      // margin-bottom: 2.125rem;
      @media (min-width: 1024px) {
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
      }
    }
  }
}

.Content {
  font-size: 0.875rem;
  line-height: 138%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.0075em;
  color: map-get($colors, c1);
  // padding: 9px;
  padding-left: 9px;
  padding-right: 9px;
  margin-top: 2.125rem;
  margin-bottom: 2.125rem;
}
.ArrowWrapper {
  height: 23.5625rem;
  width: 1.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Arrow {
  width: 52px !important;
  height: 52px !important;
  background: #f6f7fa !important;
  border: 4px solid #ffffff !important;
  border-radius: 52px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.25);
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  &:before {
    content: none !important;
  }
  .LeftArrow {
    display: flex !important;
    justify-self: flex-start !important;
    align-self: flex-start !important;
  }
  .RightArrow {
    display: flex !important;
    justify-self: flex-end !important;
    align-self: flex-end !important;
  }
  .Svg {
    width: 4.32px;
    height: 7px;
  }

  .flexStart {
    align-self: flex-start !important;
    margin-left: 12px !important;
  }

  .flexEnd {
    align-self: flex-end !important;
    margin-right: 12px !important;
  }
}

.DotContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1.125rem;
}
.SelectedDot {
  color: map-get($colors, c22);
  width: 4px;
  height: 4px;
}
.Dot {
  color: #e9ebec;
}
@keyframes moving {
  0% {
    transform: translate(-100px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes fade-out {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(25px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}
