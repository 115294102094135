.importanceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.redDot {
  width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 40px;
  background: var(
    --light-red-gradient,
    linear-gradient(135deg, #e26c82 0%, #d14962 100%)
  );
  border: 2px solid #ffffff;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    border: 1px solid var(--9-a-3-c-46, #9a3c46);
    background: var(--e-93489, #e93489);
  }
}

.yellowDot {
  width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 40px;
  background-color: #f4bb44;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    border: 1px solid var(--f-2-c-75-e, #f2c75e);
    background: var(--f-2994-a, #f2994a);
  }
}
