.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 120px);
  width: 90%;
  margin: 27px auto;
}
.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 138%;
  /* or 33px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
  padding-top: 14px;
}
.description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  /* or 25px */

  text-align: center;
  letter-spacing: 0.0075em;
  padding-top: 47px;
  padding-bottom: 100px;
}
.smile {
  padding-top: 47px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* CC_Orange */

  color: #e37a3e;
}
.information {
  position: absolute;
  bottom: 100px;
  width: 90%;
  text-align: center;
}
.image {
  width: 70px;
  height: 65px;
}
.fullCamera {
  width: 100vw;
  height: calc(100vh - 80px);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}

.fullCamera video {
  height: calc(100vh - 80px) !important;
}

.takenPhoto {
  border-radius: 30px;
  height: 100%;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}
.takenPhotoContainer {
  position: relative;
  width: 100%;
  height: 60vh;
  margin: 30px auto;
  text-align: center;
}
.retakeIcon {
  position: absolute;
  bottom: -100px;
  left: calc(50% - 95px);
  cursor: pointer;
}

@media (min-width: 1200px) {
  video {
    width: unset !important;
    min-width: 768px;
  }

  .fullCamera img {
    width: unset !important;
    min-width: 768px;
  }
}
