@import "../../styles/_variables.scss";

.Title {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 24px;
  text-align: center;
  color: #000000;
}

.Content {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
  color: #000000;
}
.List {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}
.Item {
  .dot {
    display: flex;
    width: 8px;
    height: 8px;
    min-width: 8px;
    border-radius: 8px;
    background-color: #44c032;
    margin-right: 10px;
  }
  p {
    margin-bottom: 0px !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 20px;
  color: #000000;
  height: auto;
  width: 100%;
  min-height: 58px;
  display: flex;
  margin-bottom: 19px;
  // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  // border-radius: 4px;
  padding: 10px 11px 10px 12px;
}
.Image {
  width: 90%;
  height: auto;
}
.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
.SiteRuleContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 140px);
}
.ImageContainer {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  top: 48px;
}
.Image {
  display: flex;
  flex: 1;
  background-size: cover;
  background-position: center center;
}
ul {
  list-style-type: disc;
}

.RuleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 158px;
  margin-left: 15px;
  margin-right: 15px;

  margin-bottom: 50px;
  width: 100%;
  list-style-type: circle;
  div {
    list-style-type: circle;
    width: 100%;
  }
  li {
    list-style-type: circle;
    width: auto;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* KS_28353C */

    color: #28353c;
  }
}
