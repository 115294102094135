.mobile {
  display: none;
}

.laptop {
  display: block;
  margin: 0 auto;
  width: 650px;
}

@media (max-width: 768px) {
  .mobile {
    display: block;
  }

  .laptop {
    display: none;
  }
}
