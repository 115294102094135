@import "../../styles/variables";
.completeContainer {
  min-height: calc(100vh - 140px);
}
.Title {
  font-size: 5vh;
  color: map-get($colors, c3);
  font-family: Open Sans;
  font-weight: 700;
}
.Content {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  color: map-get($colors, c3);
  text-align: center;
}
.CheckboxWrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 275px;
  max-width: 275px;
  width: 100%;
  img {
    margin: 10vh auto;
  }
}
.Link {
  width: 100%;
}

.TickHidden
{
  opacity: 0;
  transform: scale(0.1);
  transition: 300ms;
}

.TickVisible
{
  opacity: 1;
  transform: scale(1);
  transition: 300ms;
  transition-delay: 200ms;
}

@media (min-width: 1024px) {
  .CheckboxWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 350px;
    max-width: 350px;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .CheckboxWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 250px;
    max-width: 250px;
    width: 100%;
  }
}
@media (max-width: 320px) {
  .CheckboxWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 200px;
    max-width: 200px;
    width: 100%;
  }
}
